import React, { useContext, useState, useEffect } from "react";
import { ReactComponent as IconeNotificacao } from "../../assets/icons/notifications.svg";
import { ThemeProvider, useTheme } from "@emotion/react";

import Popup from "reactjs-popup";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { BotaoBaseAzulSemFundo } from "../Botao/styles";
import styled from "@emotion/styled";

const OverlayFechar = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* opacity: ${({ $aberto }) => ($aberto ? 1 : 0)};
  visibility: ${({ $aberto }) => ($aberto ? "visible" : "hidden")}; */
  transition: all 0.45s ease-out;
  z-index: -1;
`;

const PopupCustomizado = styled(Popup)`
  &-content {
    width: 388px !important;
    height: auto;
    animation: delayPopup 0.3s ease-in-out forwards;
    background-color: ${(props) => props.theme.cores.surface2};
    padding: 0;
    padding-bottom: 16px;

    span {
      font-weight: 600;
    }
  }

  @keyframes delayPopup {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextoSemNotificacao = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  letter-spacing: 0.4px;
  color: ${(props) => props.theme.cores.tipoDaLoja};
  padding: 20px 0px 10px 5px;
`;

const NotificacaoEstilizada = ({
  titulo,
  subtitulo,
  data,
  entidade,
  descricao,
  corIcone,
}) => {
  const theme = useTheme();
  return (
    <Coluna
      style={{
        minHeight: "129px",
        justifyContent: "space-between",
        padding: "16px 4px 12px 0",
        borderBottom: "1px solid " + theme.cores.textoSimples,
      }}
    >
      <Coluna
        style={{
          gap: "4px",
        }}
      >
        <Linha>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: "9px",
                height: "9px",
                borderRadius: "50%",
                backgroundColor: corIcone,
              }}
            />
            <h1 style={{ fontSize: "16px" }}>{titulo}</h1>
          </div>
          <p>{data}</p>
        </Linha>
        <p>{subtitulo}</p>
      </Coluna>
      <Coluna>
        <p>
          <span>Entidade: </span>
          {entidade}
        </p>
        <Linha>
          <p>
            <span>Descrição: </span>
            {descricao}
          </p>
          <BotaoBaseAzulSemFundo
            style={{
              borderRadius: "0px",
            }}
          >
            Resolver
          </BotaoBaseAzulSemFundo>
        </Linha>
      </Coluna>
    </Coluna>
  );
};

const IconeNotificacaoEstilizado = styled(IconeNotificacao)`
  font-size: 32px;
  fill: ${({ theme }) => theme.cores.brand40neutral100};
  cursor: pointer;

  &.active {
    animation: ring 0.5s ease-in-out;
  }

  @keyframes ring {
    from {
    }
    33% {
      transform: rotate(-15deg);
      transform-origin: 50% 0 0;
    }
    66% {
      transform: rotate(15deg);
      transform-origin: 50% 0 0;
    }
    to {
    }
  }
`;

const Aba = styled.div`
  cursor: pointer;
  user-select: none;

  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 32px;
  border-bottom: 1px solid
    ${({ theme, $ativa }) =>
    $ativa ? theme.cores.actionActive : theme.cores.textoSimples};
  transition: border-bottom 0.3s ease-in-out;

  p {
    color: ${({ theme, $ativa }) =>
    $ativa ? theme.cores.actionActive : theme.cores.textoSimples};
    transition: color 0.3s ease-in-out;
  }
`;

const Abas = ({ onClick }) => {
  const [abaAtiva, setAbaAtiva] = useState(0);
  const theme = useTheme();

  const abas = ["Pendências", "Concentrador"];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${abas.length}, 1fr)`,
      }}
    >
      {abas.map((aba, index) => (
        <Aba
          key={index}
          onClick={() => {
            setAbaAtiva(index);

            /* NOTA
                aqui provavelmente vai rolar a integração com a api
            */
          }}
          $ativa={abaAtiva === index}
        >
          <p>{aba}</p>
        </Aba>
      ))}
    </div>
  );
};

const Notificacoes = ({ mobile = false }) => {
  const { largura } = useTamanhoTela();
  const theme = useTheme();
  const [aberto, setAberto] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (aberto) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [aberto]);

  useEffect(() => {
    setAberto(false);
  }, [largura]);

  return mobile ? (
    <div>
      <OverlayFechar
        onClick={() => setAberto(false)}
        style={{
          opacity: aberto ? 1 : 0,
          visibility: aberto ? "visible" : "hidden",
        }}
      />
      <div
        className="popup-content"
        style={{
          position: "absolute",
          top: aberto ? "110px" : "-541px",
          left: "0",
          zIndex: "-1",
          backgroundColor: theme.cores.surfaceBackground1,
          width: "100%",
          transition: "top 0.5s ease-in-out",
          borderRadius: "0 0 8px 8px",
          border: "none",
          paddingBottom: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "16px 16px 0 16px",
          }}
        >
          <h1 style={{ fontSize: "23px" }}>Notificações</h1>
          <Abas onClick={setNotificacoes} />
        </div>
        <div
          style={{
            width: "100%",
            maxHeight: "435px",
            overflowY: "auto",
            padding: "0 16px 0 16px",
            fontSize: "14px",
          }}
        >
          {notificacoes.length > 0 ? (
            notificacoes.map((notificacao, index) => (
              <NotificacaoEstilizada key={index} {...notificacao} />
            ))
          ) : (
            <TextoSemNotificacao>Nenhuma notificação</TextoSemNotificacao>
          )}
        </div>
      </div>
      <IconeNotificacaoEstilizado
        className={aberto ? "active" : ""}
        // $aberto={aberto}
        style={{ marginTop: mobile ? "20px" : 0 }}
        onClick={() => setAberto(!aberto)}
      />
    </div>
  ) : (
    <PopupCustomizado
      trigger={
        <IconeNotificacaoEstilizado
          className={aberto ? "active" : ""}
          // $aberto={aberto}
          style={{ marginTop: mobile ? "20px" : 0 }}
        />
      }
      open={aberto}
      onClose={() => setAberto(false)}
      onOpen={() => setAberto(true)}
      // position={mobile ? "bottom right" : "bottom left"}
      arrowStyle={{ visibility: "hidden" }}
      offsetY={12}
      offsetX={mobile ? 20 : -20}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px 16px 0 16px",
        }}
      >
        <h1 style={{ fontSize: "23px" }}>Notificações</h1>
        <Abas onClick={setNotificacoes} />
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: "435px",
          overflowY: "auto",
          padding: "0 16px 0 16px",
          fontSize: "14px",
        }}
      >
        <TextoSemNotificacao>Nenhuma notificação recebida</TextoSemNotificacao>
      </div>
    </PopupCustomizado>
  );
};

export default Notificacoes;