import { Fragment, useEffect, useState } from "react";
import {
  BotaoCancelar,
  BotaoComprarAgora,
  BotaoDetalhesDoPlano,
  BotaoFazerPagamento,
  CabecalhoAccordion,
  CabecalhoCardPlano,
  CabecalhoPopup,
  CardPlano,
  ContainerAccordion,
  ContainerBotoes,
  ContainerBotoesPopup,
  ContainerBottomSheet,
  ContainerDireitaAccordion,
  ContainerEsquerdaAccordion,
  ContainerLinhaAccordion,
  ContainerOverflow,
  ContainerPopup,
  Hr,
  LinhaCard,
  LinhaClara,
  LinhaEscura,
  PopupCustomizado,
  SubtituloCard,
  TextoBotaoCancelar,
  TextoBotaoComprarAgora,
  TextoBotaoDetalhesDoPlano,
  TextoBotaoFazerPagamento,
  TextoCabecalhoAccordion,
  TituloCard,
  TituloLinhaCard,
  TituloPopup,
  ValorLinhaCard,
} from "./styles";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { BottomSheetCustomizada } from "../../../pages/gerenciamentoAssinaturaPlanos/detalhesPlano/formaPagamento/styles";
import { IconeBotao } from "../../../pages/selecaoEmpresas/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSummary, CircularProgress, Radio } from "@mui/material";
import {
  StyledAccordion,
  TextoLinha,
  TypographyTitulo,
} from "../../../pages/contratoCustomizado/cadastro/styles";
import { Check } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import ModalDetalharPlano from "../../../pages/gerenciamentoAssinaturaPlanos/comparacaoPlanos";
import { useApi } from "../../../contexts/api";
import formatarReal from "../../../utils/formatarReal";
import { toast } from "react-toastify";
import { BotaoVerdePreenchido } from "../../Botao";
import { INTEGRADOR, PLATAFORMA } from "../../../enums/TipoEmpresa";
export function ContratoPadrao({
  nome,
  comparacao = false,
  idPlanoPadrao,
  valorMensal,
  valorTrimestral,
  valorSemestral,
  valorAnual,
  irParaPagamento,
  voltarParaTelaAnterior,
  plano,
  tipoPlano,
  comprarBloqueado = false,
  setComprarBloqueado = () => {},
  tipoContrato = "consumidor",
  idEmpresa = null,
}) {
  const { get, post, put } = useApi();

  const [comparacaoPlanosObjeto, setComparacaoPlanosObjeto] = useState([]);
  const [detalhesPlanoObjeto, setDetalhesPlanoObjeto] = useState([]);

  const [loadingCalculo, setLoadingCalculo] = useState(false);

  function getTipoPlano() {
    if (tipoPlano === "WAYCHEF") {
      return "/waychef";
    } else if (tipoPlano === "WAYMENU") {
      return "/waymenu";
    }
  }

  function getTipoPlataformaIntegrador() {
    let sessao = JSON.parse(localStorage.getItem("sessao"));
    if (sessao.empresaDTO.tipoEmpresa === PLATAFORMA) {
      return "plataforma";
    } else if (sessao.empresaDTO.tipoEmpresa === INTEGRADOR) {
      return "integrador";
    }
  }

  function buscarPlanoDetalhar(id) {
    if (id && comparacao) {
      let url = idEmpresa
        ? `/contrato/${tipoContrato}${getTipoPlano()}/empresa-contrato/comparar-planos/${id}/${idEmpresa}`
        : `/contrato/${tipoContrato}${getTipoPlano()}/empresa-contrato/comparar-planos/${id}`;

      get(url)
        .then((response) => {
          let comparacaoPlanos = {};

          response.data.forEach((item) => {
            if (comparacaoPlanos[item.descricaoModuloContrato]) {
              comparacaoPlanos[item.descricaoModuloContrato].push(item);
            } else {
              comparacaoPlanos[item.descricaoModuloContrato] = [];
              comparacaoPlanos[item.descricaoModuloContrato].push(item);
            }
          });

          setComparacaoPlanosObjeto(comparacaoPlanos);
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            "Desculpe! Houve um erro ao obter os detalhes dos planos."
          );
        });
    } else if (id && !comparacao) {
      // let url = idEmpresa
      //   ? `/contrato/${tipoContrato}${getTipoPlano()}/empresa-contrato/plano-padrao/plano-resumido/${id}/${idEmpresa}`
      //   : `/contrato/${tipoContrato}${getTipoPlano()}/plano-padrao/plano-resumido/${id}`;

      let url = idEmpresa
        ? `/contrato/${tipoContrato}${getTipoPlano()}/empresa-contrato/plano-padrao/plano-resumido/${id}/${idEmpresa}`
        : `/contrato/${tipoContrato}${getTipoPlano()}/plano-padrao/plano-resumido/${id}`;

      const tipoEmpresa = getTipoPlataformaIntegrador();
      if (tipoEmpresa) {
        url = `/contrato/${tipoEmpresa}${getTipoPlano()}/plano-padrao/${id}`;
      }
      get(url)
        .then((response) => {
          let itensPlano = {};
          response.data[
            tipoPlano === "WAYCHEF"
              ? "itemPlanoWaychefDTOList"
              : "itemPlanoWaymenuDTOList"
          ]?.forEach((item) => {
            if (itensPlano[item.descricaoModuloContrato]) {
              itensPlano[item.descricaoModuloContrato].push(item);
            } else {
              itensPlano[item.descricaoModuloContrato] = [];
              itensPlano[item.descricaoModuloContrato].push(item);
            }
          });

          setDetalhesPlanoObjeto(itensPlano);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Desculpe! Houve um erro ao obter os detalhes do plano.");
        });
    }
  }

  useEffect(() => {
    buscarPlanoDetalhar(idPlanoPadrao);
  }, []);

  async function fazerPagamento() {
    if (loadingCalculo || comprarBloqueado) {
      return;
    } else {
      //Para evitar multiplos cliques no botão de comprar
      setLoadingCalculo(true);
      setComprarBloqueado(true);
    }

    let listaTotal = [];
    let listaDetalhes = [];

    let descricaoCompra;
    let valorCompra;
    let valorTotal;
    let avisoSuperiorEsquerda;

    switch (plano?.tipoRecorrenciaPlano) {
      case "MENSAL":
        descricaoCompra = `${plano?.descricao} (Mensal)`;
        valorCompra = plano?.valorMensal;
        break;
      case "TRIMESTRAL":
        descricaoCompra = `${plano?.descricao} (Trimestral)`;
        valorCompra = plano?.valorTrimestral;
        break;
      case "SEMESTRAL":
        descricaoCompra = `${plano?.descricao} (Semestral)`;
        valorCompra = plano?.valorSemestral;
        break;
      case "ANUAL":
        descricaoCompra = `${plano?.descricao} (Anual)`;
        valorCompra = plano?.valorAnual;
        break;
    }

    if (
      valorCompra === null ||
      valorCompra === undefined ||
      !plano?.tipoRecorrenciaPlano
    ) {
      toast.error("Erro ao obter o valor do plano!");
      return;
    }

    listaTotal.push({
      descricao: descricaoCompra,
      valor: valorCompra,
    });

    if (comparacao) {
      let url = idEmpresa
        ? `/contrato/${tipoContrato}${getTipoPlano()}/empresa-contrato/calcular-troca-plano/${idPlanoPadrao}/${idEmpresa}`
        : `/contrato/${tipoContrato}${getTipoPlano()}/empresa-contrato/calcular-troca-plano/${idPlanoPadrao}`;
      const comparacaoPlanosCalculo = await get(url)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            toast.error("Erro ao calcular os valores da troca!");
            throw new Error(response);
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Erro ao calcular os valores da troca!"
          );
          console.error(error);
        });

      if (
        comparacaoPlanosCalculo?.valorTotalPlanoNovo === null ||
        comparacaoPlanosCalculo?.valorTotalPlanoNovo === undefined
      ) {
        toast.error("Erro ao obter o valor do plano!");
        setLoadingCalculo(false);
        setComprarBloqueado(false);
        return;
      }

      valorTotal = parseFloat(comparacaoPlanosCalculo?.valorTotalPlanoNovo);

      listaTotal.push({
        descricao: "Crédito",
        valor: parseFloat(comparacaoPlanosCalculo?.descontoPlanoNovo) * -1,
      });

      if (comparacaoPlanosCalculo?.descontoPlanoNovo > 0) {
        const Descricao = (
          <Fragment>
            Percebemos que você só usou <strong>uma parte do seu plano</strong>,
            por isso será creditado para a troca do seu plano o valor de{" "}
            <strong>
              {formatarReal(comparacaoPlanosCalculo?.descontoPlanoNovo)} reais.
            </strong>
            <br />
            {comparacaoPlanosCalculo?.diasAcrescentadosAoVencimento ? (
              <Fragment>
                O que ultrapassar o valor do seu novo plano,
                <strong> será convertido em dias extras</strong>, e resultará em
                um acréscimo de{" "}
                <strong>
                  {comparacaoPlanosCalculo?.diasAcrescentadosAoVencimento} dias.
                </strong>
              </Fragment>
            ) : null}
          </Fragment>
        );
        avisoSuperiorEsquerda = {
          titulo: `Plano ${comparacaoPlanosCalculo?.descricaoPlanoAntigo}`,
          valor: comparacaoPlanosCalculo?.valorPlanoAntigo,
          descricao: Descricao,
        };
      }
    }

    const novoContratoPagamento = () => {
      let corpo =
        tipoPlano == "WAYCHEF"
          ? {
              idPlanoPadraoWaychef: idPlanoPadrao,
            }
          : {
              idPlanoPadraoWaymenu: idPlanoPadrao,
            };

      return post(
        "/contrato/consumidor/waybe/empresa-contrato/aplicacao/novo-contrato",
        corpo
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Plano adquirido com sucesso!");
            voltarParaTelaAnterior();
          } else {
            throw new Error(response);
          }
        })
        .catch((error) => {
          toast.error(
            error.data?.message ? error.data?.message : "Erro ao contratar!"
          );
        });
    };

    const trocarContratoPagamento = () => {
      return put(
        "contrato/consumidor/waychef/empresa-contrato/troca-plano/" +
          idPlanoPadrao,
        {}
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Plano adquirido com sucesso");

            voltarParaTelaAnterior();
          } else {
            throw new Error(response);
          }
        })
        .catch((error) => {
          toast.error(
            error.data?.message ? error.data?.message : "Erro ao contratar!"
          );
        });
    };

    let dadosPagamento = {
      titulo: comparacao ? "Alteração do plano" : "Contratação do plano",
      listaTotal: listaTotal,
      listaDetalhes: listaDetalhes,
      realizarPagamentoFuncao: comparacao
        ? trocarContratoPagamento
        : novoContratoPagamento,
      valorTotal: comparacao ? valorTotal : undefined,
      avisoSuperiorEsquerda: avisoSuperiorEsquerda,
      idPlanoPadrao,
    };
    setLoadingCalculo(false);
    setComprarBloqueado(false);
    irParaPagamento(dadosPagamento);
  }

  return (
    <CardPlano>
      <CabecalhoCardPlano>
        <TituloCard>{nome}</TituloCard>
        {comparacao && (
          <SubtituloCard>
            Para ativar esse plano, primeiro você precisa cancelar seu plano
            atual
          </SubtituloCard>
        )}
      </CabecalhoCardPlano>
      <LinhaCard>
        <TituloLinhaCard>Mensal</TituloLinhaCard>

        <ValorLinhaCard>{formatarReal(valorMensal)}</ValorLinhaCard>
      </LinhaCard>
      <Hr />
      <LinhaCard>
        <TituloLinhaCard>Trimestral</TituloLinhaCard>
        <ValorLinhaCard>{formatarReal(valorTrimestral)}</ValorLinhaCard>
      </LinhaCard>
      <Hr />
      <LinhaCard>
        <TituloLinhaCard>Semestral</TituloLinhaCard>
        <ValorLinhaCard>{formatarReal(valorSemestral)}</ValorLinhaCard>
      </LinhaCard>
      <Hr />
      <LinhaCard style={{ marginBottom: 28 }}>
        <TituloLinhaCard>Anual</TituloLinhaCard>
        <ValorLinhaCard>{formatarReal(valorAnual)}</ValorLinhaCard>
      </LinhaCard>
      <ContainerBotoes>
        <ModalDetalharPlano
          tipoContrato={tipoContrato}
          comparacao={comparacao}
          planosObjeto={
            comparacao ? comparacaoPlanosObjeto : detalhesPlanoObjeto
          }
          descricaoNovoPlano={nome}
          componenteTrigger={
            <BotaoDetalhesDoPlano>
              <TextoBotaoDetalhesDoPlano>
                <span>Detalhes do plano</span>
              </TextoBotaoDetalhesDoPlano>
            </BotaoDetalhesDoPlano>
          }
          idPlanoParaDetalhar={idPlanoPadrao}
          fazerPagamento={fazerPagamento}
          loadingCalculo={loadingCalculo}
        />

        <BotaoVerdePreenchido
          tamanho={"SM"}
          onClick={fazerPagamento}
          texto={"Comprar agora"}
          icone={
            loadingCalculo ? (
              <CircularProgress
                style={{
                  color: "white",
                  fill: "white",
                  marginRight: "-5px",
                  marginLeft: "-10px",
                }}
                size={13}
              />
            ) : null
          }
        />
      </ContainerBotoes>
    </CardPlano>
  );
}
