import styled from "@emotion/styled";

export const Container = styled.div`
    margin-bottom: 20px;
    margin-top: -10px;
    @media (max-width: 768px) {
        margin-top: 15px;
        margin-bottom: 0px;
    }
`;

export const Texto = styled.p`

`