import { toast } from "react-toastify";
import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL_API;
export const clientKey = '1EA196209FAD49D919692C391FD383D3'
export const baseUrlERP = process.env.REACT_APP_BASE_URL


const infoAPI = {
    baseURL: baseURL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('tokenPlataformaCashback')}`,
    },
    validateStatus: () => true,
};

export const post = (url, data,) => {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
        api
            .post(url, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const put = (url, data,) => {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
        api
            .put(url, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const patch = (url, data,) => {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
        api
            .patch(url, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    });
}


export const get = (url) => {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
        api
            .get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const del = (url) => {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
        api
            .delete(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const realizarLogoff = async () => {
    try {
        await del("/usuario-sessao/client/usuario/consumidor/encerrar-sessao").then((res) => {
            limparSessao();
        }).catch((erro) => {
            limparSessao();
        })
    } catch (error) {
        console.error(error)
    }
}

const limparSessao = () => {
    localStorage.removeItem("tokenPlataformaCashback");
    localStorage.removeItem('cashback_nome_usuario')
    window.location.href = `${baseUrlERP}/crm-consumidor`;
}



