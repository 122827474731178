import React, { useContext, useEffect, useState } from "react";
import { TemaCustomizadoContext } from "../../contexts/theme";

import { useTheme } from "@emotion/react";
import useTamanhoTela from "../../utils/useTamanhoTela";
import {
  BaseBotaoFatura,
  Loader,
  MenuItemFilhoContainer,
  SwitchEstilizado,
} from "./styles";
import { useAuth } from "../../contexts/auth";
import { useApi } from "../../contexts/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const FuncionamentoEmpresa = () => {
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const { dadosAuth } = useAuth();
  const { get, post, put } = useApi();

  const [empresaAberta, setEmpresaAberta] = useState(true);
  const [exibirBotao, setExibirBotao] = useState(false);
  const [loadingDados, setLoadingDados] = useState(true);

  function mudarEmpresa() {
    put("configuracoes-lojas/empresa/" + dadosAuth.empresaDTO.id, {
      funcionamentoEmpresa: !empresaAberta,
    })
      .then((response) => {
        setEmpresaAberta(!empresaAberta);
        toast.success("Funcionamento da empresa alterado com sucesso");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro ao alterar funcionamento da empresa");
        obterDadosFuncionamentoEmpresa();
      });
  }

  async function verificarLojaAberta() {
    const diasSemana = [
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
      "domingo",
    ];

    let diaSemanaAtual = diasSemana.indexOf(
      dayjs().locale("pt-br").format("dddd")
    );

    let responseHorarios = await post(
      "configuracoes-lojas/empresa-horario-funcionamento/buscar-todos?page=0&size=100",
      {
        idEmpresa: dadosAuth.empresaDTO.id,
        dia: diaSemanaAtual,
      }
    );

    let aberto = false;

    responseHorarios?.data?.content?.forEach((horario) => {
      let horaAtual = dayjs();
      let horaAbertura = dayjs(horario.horaAbertura, "HH:mm:ss");
      let horaFechamento = dayjs(horario.horaFechamento, "HH:mm:ss");

      if (
        horaAtual.isAfter(horaAbertura) &&
        horaAtual.isBefore(horaFechamento)
      ) {
        aberto = true;
      }
    });

    return aberto;
  }

  async function obterDadosFuncionamentoEmpresa() {
    setLoadingDados(true);

    if (
      dadosAuth?.contratoWaymenuAtualDTO?.listaItens?.length > 0 &&
      (await verificarLojaAberta())
    ) {
      setExibirBotao(true);
    } else {
      setExibirBotao(false);
      return;
    }

    get("configuracoes-lojas/empresa/" + dadosAuth.empresaDTO.id)
      .then((response) => {
        setLoadingDados(false);
        let dadosEmpresa = response.data;
        setEmpresaAberta(dadosEmpresa?.funcionamentoEmpresa);
      })
      .catch((error) => {
        setLoadingDados(false);
        console.error(error);
        toast.error("Erro ao buscar dados de funcionamento da empresa");
      });
  }

  useEffect(() => {
    obterDadosFuncionamentoEmpresa();
  }, [dadosAuth]);

  return exibirBotao && !loadingDados ? (
    largura > 768 ? (
      <BaseBotaoFatura checked={empresaAberta}>
        <SwitchEstilizado
          onChange={() => {
            mudarEmpresa();
          }}
          checked={empresaAberta}
          valor={empresaAberta}
        />
        {empresaAberta ? <p>Recebendo pedidos</p> : <p>Pedidos pausados</p>}
      </BaseBotaoFatura>
    ) : (
      <MenuItemFilhoContainer>
        <SwitchEstilizado
          style={{ marginRight: "8px" }}
          onChange={() => {
            mudarEmpresa();
          }}
          checked={empresaAberta}
          valor={empresaAberta}
        />
        {empresaAberta ? <p>Recebendo pedidos</p> : <p>Pedidos pausados</p>}
      </MenuItemFilhoContainer>
    )
  ) : <Loader />;
};

export default FuncionamentoEmpresa;