import { useState, useEffect, useContext } from "react";
import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconeExpandir } from "../../assets/icons/expand_more.svg";
import { ItemAdicionarDashboard, ItemMenu } from "./styles";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const ContainerNaoSelecionavel = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icone-menu {
    color: ${(props) => props.theme.cores.textoSimples};
    fill: ${(props) => props.theme.cores.textoSimples};
    ${(props) =>
      props.aberto && `animation: trocarCor 0.3s ease-in-out forwards`};

    &-direita {
      transform: rotate(-90deg);
    }

    &-esquerda {
      transform: rotate(90deg);
    }
  }

  .icone-menu-painel-transicao-inverter {
    ${(props) =>
      props.$aberto && `animation: girarIcone 0.3s ease-in-out forwards`};
  }

  @keyframes girarIcone {
    0% {
      transform: rotate(0deg);
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      transform: rotate(180deg);
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }

  @keyframes trocarCor {
    0% {
      color: ${(props) => props.theme.cores.textoSimples};
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      color: ${(props) => props.theme.cores.brand40Default};
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }
`;

const ComponentePaginado = ({
  Filho,
  navegacaoInicial,
  Separador = () => <></>,
  mobile = false,
}) => {
  const theme = useTheme();
  const [navegacao, setNavegacao] = useState(navegacaoInicial);
  const [expandido, setExpandido] = useState({});
  const location = useLocation();

  useEffect(() => {
    setNavegacao(navegacaoInicial);
  }, [navegacaoInicial]);
  const toggleExpandir = (index, temFilhos, nome) => {
    if (temFilhos) {
      setExpandido((prevState) => ({
        ...prevState,
        [nome + index]: !prevState[nome + index],
      }));
    }
  };
  const intercalar = (array, Separador) => {
    let resultado = [];
    for (let i = 0; i < array.length; i++) {
      resultado.push(array[i]);
      if (i !== array.length - 1) {
        resultado.push(<Separador key={i} />);
      }
    }
    return resultado;
  };

  const tipoAdicionarDashboard = (texto) => {
    try {
      if (texto === "ativo") {
        return <></>;
      }

      if (texto === "Novo") {
        return (
          <ItemAdicionarDashboard>
            Novo
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ color: theme.cores.neutral100 }}
              fontSize={19}
            />
          </ItemAdicionarDashboard>
        );
      }

      return <ItemMenu>{texto}</ItemMenu>;
    } catch (e) {}
  };

  const mapearBotoesNavegacao = (nav) => {
    if (!nav || nav.length === 0 || Object.values(nav).length === 0) {
      return <></>;
    }

    let botoes = nav?.map((item, index) => {
      const nome = item.nome;
      const filhos = item.filhos || [];
      const estaExpandido = expandido[nome + index];
      const temFilhos = filhos.length > 0;
      return (
        <div style={{ width: "100%" }} key={index + nome + "navegacao"}>
          <Filho texto={nome}>
            <div
              onClick={(event) => {
                event.stopPropagation();
                toggleExpandir(index, temFilhos, nome);
                if (!temFilhos && item.onClick) {
                  item.onClick(event);
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {tipoAdicionarDashboard(nome, filhos.length > 0)}
              {temFilhos && (
                <IconeExpandir
                  className={`icone-menu ${
                    estaExpandido ? "icone-menu-direita" : "icone-menu-esquerda"
                  }`}
                />
              )}
            </div>
          </Filho>

          {estaExpandido && temFilhos && (
            <div
              style={{
                marginLeft: "16px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {mapearBotoesNavegacao(filhos)}
            </div>
          )}
        </div>
      );
    });

    return intercalar(botoes, Separador);
  };

  return (
    <ContainerNaoSelecionavel>
      {mapearBotoesNavegacao(navegacao)}
    </ContainerNaoSelecionavel>
  );
};

export default ComponentePaginado;
