import styled from "@emotion/styled";
import { TextField, Tooltip } from "@mui/material";

export const CampoDeTextoBase = styled(TextField)`
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? "#5C5C5C"
      : '#f0f6fb '};

  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */

  border: none;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "16px;")};
    ${(props) => props.value && `padding-top: 7px;`};
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  div {
    height: 56px;
  border-radius: 16px;

  }

  .Mui-disabled {
    cursor: ${({ typeNumber }) => typeNumber === false && 'not-allowed'};
    opacity: ${({ typeNumber }) => (typeNumber === true ? 1 : 1)};
    color: ${({ theme }) => theme.cores.corInputDisabled};
    -webkit-text-fill-color: ${({ theme }) => theme.cores.corInputDisabled};
  }

  ${(props) =>
    !props.theme.temaDarkAtivo &&
    `
      opacity: 1;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.ocultarSetas &&
    `
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
  `}

  .MuiFilledInput-input {
    &:has(input:focus) label {
      padding-top: 0px;
      padding-left: 56px;
    }
  }

  .MuiInputBase-root {
    padding-right: 20px !important
  }

  .MuiAutocomplete-endAdornment {
    margin-top: 12px
  }
`;

export const TitleGroup = styled.h1`
font-size: 18px;
 font-weight: 700;
  margin: 8px 0;
  padding-left: 8px;
`

export const OptionItem = styled.a`

text-decoration:none;
display:block;
width: 100%;
font-size: 16px;
font-family: "Source sans pro", sans-serif;
color: ${({ theme }) => theme.cores.surfaceActionActivePrimary};
                                
`


export const TextoTooltip = styled.p`
font-size: 15px;
color:#fff;  
`