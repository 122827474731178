import styled from "@emotion/styled";
import { Autocomplete, Tooltip } from "@mui/material";
import { X } from "phosphor-react";

export const ContainerGlobal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px 40px;

  @media (max-width: 768px) {
    position: relative;
    > div {
      flex: 1;
    }
  }
`;

export const TextoTitulo = styled.h4`
  color: ${({ theme }) => theme.adaptativo("neutral10", "neutral100")};
  font-family: "comfortaa", sans-serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 25px;

  @media (max-width: 800px) {
    margin: 10px 20px;
  }
`;
export const ImagemWaybe = styled.div`
  max-width: 350px;
  min-height: 66px;
  background-position: center;
  background-size: contain;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  display: flex;
`;

export const ContainerConteudo = styled.div`
  width: 100%;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 16px 40px 45px;
`;
export const Conteudo = styled.div`
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  padding: 16px 24px;

  @media (max-width: 768px) {
    padding: 16px 8px;
  }
`;

export const ItensCadastro = styled.div`
  padding: 16px 24px 80px;
`;
export const ContainerEtapas = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

export const Etapa = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  width: 100%;

  > p {
    color: ${({ theme, atual }) =>
      atual
        ? theme.cores.surfaceActionActivePrimary
        : theme.cores.textoSimples};
    white-space: nowrap;
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    background-color: ${({ theme, atual }) =>
      atual ? theme.cores.surfaceActionActivePrimary : "transparent"};
    border-radius: 99px;
    color: ${({ theme, atual }) =>
      atual ? theme.cores.textoInverso : theme.cores.textoSimples};
    border: 1px solid
      ${({ theme, atual }) =>
        atual ? "transparent" : theme.cores.textoSimples};
  }
`;

export const Separador = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.cores.corHr};
`;

export const WrapperContratos = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 350px));
  gap: 32px;
  margin-top: 32px;
  margin-bottom: 50px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  }
`;
export const Contrato = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.cores.stroke};
  border-radius: 24px;
  position: relative;
  cursor: pointer;

  background-color: ${({ theme, selecionado }) =>
    selecionado ? theme.cores.bgCard : "transparent"};

  > h1 {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 400;
  }

  > span {
    font-size: 23px;
    font-weight: 700;
    font-family: "Comfortaa", sans-serif;
    margin-bottom: 5px;
  }

  > p {
    font-size: 17px;
    margin-bottom: 24px;
  }
`;

export const Especificacoes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
  > div {
    display: flex;
    gap: 8px;
    align-items: center;

    > p {
      white-space: nowrap;
    }
  }
`;

export const ChipRecomendado = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 99px;
  width: 100px;
  height: 24px;
  background-color: ${({ theme }) => theme.cores.chipRecomendado};

  font-size: 12px !important;
  font-weight: 400;
  color: ${({ theme }) => theme.cores.surfaceActionActivePrimary};

  position: absolute;
  top: -12px;
`;

export const ContainerBotoes = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  @media (max-width: 768px) {
    margin-top: 40px;
    justify-content: center;
  }
`;
export const ContainerTitulo = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 32px;

  > h1 {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
`;

export const ContainerInputs = styled.div`
  display: grid;
  gap: 20px 32px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 32px 0;
`;
export const ContainerDoisInputs = styled.div`
  display: grid;
  gap: 20px 32px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 600px));
  margin: 32px 0;
`;

export const SeparadorTitulo = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.adaptativo("neutral100", "neutral0")};
`;

export const EspecificacoesSenha = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > h1 {
    font-size: 16px;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  > p {
    font-size: 15px;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  /* height: 56px; */

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
      props.value != "" || props.value != null ? `12px;` : "16px;"};
    ${(props) =>
      props.value != "" || props.value != null
        ? "padding-top: 7px;"
        : `padding-top: 2px`}
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1px solid
      ${(props) => props.theme.adaptativo("neutral50", "neutral100")};

    border-radius: 0;
  }

  .MuiInputLabel-root {
    padding-left: 0px;
    padding-bottom: 7px;
  }

  .MuiInputLabel-shrink {
    padding-top: 15px;
  }

  .MuiAutocomplete-root label {
    padding-top: 15px;
  }

  .MuiAutocomplete-tag {
    margin-top: 10px;
  }

  .MuiInputBase-root {
    background-color: ${({ theme }) =>
      theme.temaDarkAtivo
        ? String(theme.cores.neutral100).replace("1)", "0.2)")
        : "transparent"};

    padding: 13px 10px 5px;
  }

  .MuiAutocomplete-root:has(input:focus) label {
    padding-top: 15px;
  }

  .MuiAutocomplete-input {
    padding: 13px 4px 2px 5px !important;
  }

  .MuiAutocomplete-endAdornment {
    margin-top: 14px;
  }

  .MuiChip-root {
    margin: 6px 0 0;
  }
`;

export const Bloco = styled.div`
  position: relative;
  > p {
    font-size: 17px;
    color: ${({ theme }) => theme.cores.semanticDanger2};
    position: absolute;
    bottom: -25px;
  }
`;

export const ConteudoPopUp = styled.div`
  /* padding: 24px; */
  padding-bottom: 24px;
`;

export const HeaderPopup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const ContainerTextoItemSelecionado = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
`;
export const ContainerTexto = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

export const TextoItemSelecionadoExcluir = styled.div`
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 17px;

  color: ${({ theme }) => theme.adaptativo("neutralVariant10", "neutral100")};
`;
export const TextoItemSelecionado = styled.p`
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  font-size: 19px;
  line-height: 17px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 700;

  color: ${({ theme }) => theme.adaptativo("neutralVariant10", "neutral100")};
`;
export const TextoTituloModal = styled.p`
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  font-size: 26px;
  line-height: 17px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;

  color: ${({ theme }) => theme.adaptativo("neutralVariant10", "neutral100")};
`;

export const ContainerBotoesExcluir = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 32px;
  margin-top: 32px;
`;
export const XIcon = styled(X)`
  height: 24px;
  width: 24px;

  color: ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    filter: brightness(0.7);
  }
`;

export const CardPlanoSelecionado = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.cores.stroke};
  border-radius: 16px;
  width: 100%;
  max-width: 700px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      font-weight: 600;
      font-size: 16px;
      font-family: "Comfortaa", sans-serif;
    }

    > h1 {
      font-weight: 500;
      font-size: 23px;
    }
  }
`;

export const ContainerTituloTooltip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 4px;
  align-items: center;
  margin-bottom: 2px;

  p {
    font-size: 14px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.cores.textoSimples};
  }
`;

export const CustomTooltip = styled(Tooltip)`
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 99px;
  color: #fff;
`;
