import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const IconeMenu = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: ${(props) => props.color || props.theme.cores.neutral60};
  border-radius: 5px;
  /* cursor: pointer; */
`;

export const ContainerModal = styled.div`
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

export const PainelModal = styled.div`
  background-color: ${({ theme }) => theme.cores.backgroundTituloModal};
  border: 1px solid #555454;
  width: 500px;
  height: 100vh;
  border-top-left-radius: 24px;
  flex-direction: column;
  position: absolute;
  right: 0;
  padding: 15px; 
  overflow-x:auto;

  @media (max-width: 768px) {
    width: 100%;
  }
  
`;

export const LinhaTitulo = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
font-size: 32px;
padding: 10px 3px;
 `;

export const TypographyTitulo = styled(Typography)`
 font-family: "Source Sans Pro";
 font-style: normal;
 font-weight: 500;
 font-size: 20px;
 line-height: 24px;
 letter-spacing: 0.5px;
 margin: 0px 0px 16px;

 @media (max-width: 768px) {
   width: 100%;
   font-family: "neutral10";
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 18px;
 }
`;

export const AccordionStyled = styled(Accordion)`
  border-radius: 0;
  box-shadow: none;
  margin-top: 24px;

  svg {
    color: ${({ theme }) => theme.cores.textoSimples};
  }
`;

export const AccordionItem = styled(AccordionSummary)`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.cores.surface3};
  gap: 5;

  > p{
    color: ${({ theme }) => theme.cores.textoSimples};
    font-size: 20px;
    margin-left: 16px; 
  }

  > a{
    text-decoration: none;
   color: inherit;
    outline: none ;
  }

`;

export const AccordionConteudo = styled(AccordionDetails)`
  background-color: ${({ theme }) => theme.cores.surface3};
  padding: 32px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  > p{
    color: ${({ theme }) => theme.cores.textoSimples};
    font-size: 20px;
    margin-left: 16px; 
  }

  > a{
    text-decoration: none;
   color: inherit;
    outline: none ;
  }
  `;

export const Separador = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.temaDarkAtivo ? "rgba(255, 255, 255, 0.24)" : "rgba(0, 0, 0, 0.24)"};
  margin: 5px 0;
  `;

export const SubTitulo = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin: 20px 0 26px -38px;
  align-items: center;
  
  
  `;

export const LinkStyled = styled(Link)`
  padding: 14px 5px;
  border-radius: 2px;
transition: background-color 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.adaptativo("actionDisable", "surface2")};
  }
  `
