import React, { useEffect, useState } from "react";
import {
  BottomSheetCustomizada,
  ContainerBottomSheet,
  TituloModal,
  BotaoFechar,
  CardLojaImage,
  SelecionarEmpresa,
  Tabela,
} from "./styles";
import { useApi } from "../../contexts/api";
import { TabelaLib } from "../TabelaLib";
import Close from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import logoLoja from "../../assets/images/logoLoja.jpg";
import Carregando from "../../pages/carregando";
import dayjs from "dayjs";

export default function SelecionarEmpresaPopup({
  selecionarEmpresa = () => { },
  fecharTabela = () => { },
}) {
  const { post } = useApi();
  const theme = useTheme();

  //Estrutura tabela
  const [dadosIniciaisBuscados, setDadosIniciaisBuscados] = useState(false);
  const [dadosTabela, setDadosTabela] = useState([]);
  const [dadosTabelaConfig, setDadosTabelaConfig] = useState({});

  const buscarEmpresas = (pagina = 0, filtro = {}) => {
    let filtroComAtivoEngecado = { ...filtro };
    if (filtroComAtivoEngecado.colunasValores) {
      filtroComAtivoEngecado.colunasValores.status = "ATIVO";
    } else {
      filtroComAtivoEngecado.colunasValores = { status: "ATIVO" };
    }

    post(
      `/configuracoes-lojas/empresa/buscar-empresas?page=${pagina}`,
      filtroComAtivoEngecado
    )
      .then((dados) => {
        setDadosIniciaisBuscados(true);
        renderizarItensTabela(dados.data);
      })
      .catch((erro) => { });
  };

  const enumColunas = (coluna) => {
    const colunas = {
      "nomeFantasia": "NOME_FANTASIA",
      "tipo": "TIPO",
      "grupo": "GRUPO",
      "integrador": "INTEGRADOR",
      "cidade": "CIDADE",
      "uf": "UF",
      "plano": "PLANO",
      "assinatura": "ASSINATURA",
      "dataCadastro": "DATA_CADASTRO",
      "vencimento": "VENCIMENTO",
      "inscricaoFederal": "INSCRICAO_FEDERAL",
    };
    return colunas[coluna];
  };

  const formatarInscricaoFederal = (value) => {
    try {
      let valorFormatado = value.replace(/\D/g, "");

      if (/^\d+$/.test(valorFormatado)) {
        if (valorFormatado.length <= 11) {
          valorFormatado = valorFormatado
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})/, "$1-$2")
            .replace(/(-\d{2})\d+?$/, "$1");
        } else {
          valorFormatado = valorFormatado
            .replace(/(\d{2})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1/$2")
            .replace(/(\d{4})(\d{1,2})/, "$1-$2")
            .replace(/(-\d{2})\d+?$/, "$1");
        }
      }

      return valorFormatado;
    } catch (error) {
      console.error(error);
      return value;
    }
  }

  const filtrarDados = (dados) => {
    const busca = {
      ...dados.conteudoBody,
      ordenarEmpresas:
        dados?.ordenacao?.coluna == ""
          ? null
          : enumColunas(dados?.ordenacao?.coluna),
      direcionamento:
        dados?.ordenacao?.direcao == "" ? null : dados?.ordenacao?.direcao,
    };

    if (dados?.conteudoBody?.colunasValores?.inscricaoFederal) {
      busca.colunasValores.inscricaoFederal = dados.conteudoBody.colunasValores.inscricaoFederal.replace(/\D/g, "")
    }

    buscarEmpresas(0, busca);
  };

  function renderizarItensTabela(dados) {
    let dadosItems = dados?.content;
    let paginacao = dados?.pageable;

    if (dadosItems && paginacao) {
      let novosDadosTabela = {
        paginas: dados?.totalPages,
        pagina: paginacao?.pageNumber,
        mudarPagina: buscarEmpresas,
        filtragemDados: () => { },
        valoresColuna: filtrarDados,
        eventoCliqueLinha: (item) => {
          selecionarEmpresa(item.id, item.nome);
        },
        colunas: [
          {
            key: "acao",
            titulo: "Ação",
            ordenacao: false,
            campoDeTexto: false,
          },
          {
            key: "imagem",
            titulo: "Logo",
            ordenacao: false,
            campoDeTexto: false,
          },
          { key: "nomeFantasia", titulo: "Nome fantasia", ordenacao: true },
          { key: "tipo", titulo: "Tipo", ordenacao: true, campoDeTexto: true },
          { key: "grupo", titulo: "Grupo", ordenacao: true },
          { key: "integrador", titulo: "Integrador", ordenacao: true },
          { key: "inscricaoFederal", titulo: "Ins. Federal", ordenacao: false },
          { key: "cidade", titulo: "Cidade", ordenacao: true },
          { key: "uf", titulo: "UF", ordenacao: true },
          { key: "plano", titulo: "Plano", ordenacao: true },
          { key: "assinatura", titulo: "Assinatura", ordenacao: true },
          { key: "dataCadastro", titulo: "Data cadastro", ordenacao: true },
        ],
        gridColuna: `minmax(150px, 1fr) minmax(120px, 1fr) minmax(260px, 1fr) minmax(170px, 1fr) minmax(200px, 1fr) minmax(170px, 1fr) minmax(220px, 1fr) minmax(180px, 1fr) minmax(100px, 1fr) minmax(130px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr)!important`,
        nodes: [],
        nodesTabelaSecundaria: [],
      };

      for (let i = 0; i < dadosItems.length; i++) {
        let item = dadosItems[i];
        novosDadosTabela.nodes.push({
          "acao": (
            <SelecionarEmpresa
              onClick={() => selecionarEmpresa(item.id, item.nomeFantasia)}
            >
              Visualizar
            </SelecionarEmpresa>
          ),
          "imagem": (
            <CardLojaImage
              src={
                item.logo == null || item.logo == ""
                  ? logoLoja
                  : `data:image/jpg;base64,${item.logo}`
              }
            />
          ),
          "nomeFantasia": item.nomeFantasia,
          "tipo": item.tipoEmpresa,
          "grupo": item.grupo,
          "integrador": item.integrador,
          "inscricaoFederal": formatarInscricaoFederal(item.inscricaoFederal),
          "cidade": item.cidade,
          "uf": item.uf,
          "plano": item.tipoContrato,
          "assinatura": item.recorrenciaContrato,
          "dataCadastro": dayjs(item.dataCadastro).format("DD/MM/YYYY"),

          "parametro": { id: item.id, nome: item.nomeFantasia },
        });
      }

      setDadosTabelaConfig({
        containerFiltroGeral: true,
        filtroGeral: true,
        ordenacao: true,
        filtroColuna: true,
        filtroColunaVisivel: false,
        esconderColuna: false,
        accordionTabela: false,
      });

      setDadosTabela(novosDadosTabela);
    } else {
      setDadosTabela([]);
    }
  }

  useEffect(() => {
    buscarEmpresas();
  }, []);

  return (
    <BottomSheetCustomizada
      onDismiss={fecharTabela}
      open={true}
      snapPoints={({ maxHeight }) => [maxHeight * 0.8]}
      blocking={false}
    >
      <ContainerBottomSheet>
        <TituloModal>
          <h3>Alterar empresa</h3>
          <BotaoFechar onClick={fecharTabela}>
            <Close style={{ fontSize: 22, color: theme.cores.botaoModal }} />
          </BotaoFechar>
        </TituloModal>

        <Tabela>
          {!dadosIniciaisBuscados ? (
            <Carregando />
          ) : (
            dadosTabela.nodes && (
              <TabelaLib
                dadosTabela={dadosTabela}
                variante={dadosTabelaConfig}
              />
            )
          )}
        </Tabela>
      </ContainerBottomSheet>
    </BottomSheetCustomizada>
  );
}
