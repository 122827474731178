import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputAdornment, IconButton, Box, Autocomplete, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { CampoDeTextoBase, CustomTooltip, OptionItem, TextoTooltip, TitleGroup } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";

import { menusLiberados } from "../..";

const ExpandableSearch = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [searchValue, setSearchValue] = useState(null);
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef(null);

    // const handleExpand = () => setIsExpanded(true)


    // const handleCollapse = () => {
    //     setIsExpanded(false);
    //     setSearchValue(null);
    // }; 

    const formatOptionsAutocomplete = useCallback((menus) => {
        const options = [];

        const recursiveHelper = (menu, path, rootGroup) => {

            const currentPath = path ? path : menu.nome;

            const group = rootGroup || menu.nome;

            if (menu.filhos) {
                menu.filhos.forEach((filho) => {

                    if (filho.onClick !== undefined && !filho.filhos) {
                        options.push({
                            title: `${currentPath} > ${filho.nome}`,
                            group: group,
                            link: filho.onClick,
                        });
                    }


                    if (filho.filhos) {
                        recursiveHelper(filho, `${currentPath} > ${filho.nome}`, group);
                    }
                });
            }
        };

        menus.forEach((menu) => {
            recursiveHelper(menu, "", menu.nome);
        });

        return options;
    }, [menusLiberados]);


    useEffect(() => {
        console.log(formatOptionsAutocomplete(menusLiberados, 0), "formatOptionsAutocomplete(menusLiberados, 0)")
        setOptions(formatOptionsAutocomplete(menusLiberados, 0));
    }, []);
    console.log(inputRef.current, "inputRef")

    useEffect(() => {
        setSearchValue(null);
    }, [location.pathname]);

    // const filterOptions = matchS

    // useEffect(() => {
    //     if (isExpanded && inputRef.current) {
    //         inputRef.current.focus();
    //     }
    // }, [isExpanded]);
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                transition: "width 0.3s ease",
                width: isExpanded ? "25%" : "40px",
                maxWidth: "600px",
                overflow: "hidden",
                borderRadius: "16px",
            }}
        >
            {/* {!isExpanded && (
                <IconButton onClick={handleExpand}>
                    <Tooltip title={<TextoTooltip >Digite uma palavra-chave para encontrar menus e relatórios rapidamente</TextoTooltip>}>

                        <SearchIcon style={{ width: 27, height: 27 }} />

                    </Tooltip>
                </IconButton>
            )} */}
            {isExpanded && (
                <Autocomplete
                    value={searchValue}
                    onChange={(e, newValue) => setSearchValue(newValue)}
                    options={options}
                    getOptionLabel={(option) => option.title}
                    groupBy={(option) => option.group}
                    renderGroup={(params) => (
                        <div key={params.key}>
                            <TitleGroup>{params.group}</TitleGroup>
                            {params.children}
                        </div>
                    )}
                    renderOption={(props, option) => (
                        <li {...props} onClick={() => {
                            option.link();
                            inputRef.current.blur();
                        }}>
                            <OptionItem
                            >
                                {option.title}
                            </OptionItem>
                        </li>
                    )}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                        <CampoDeTextoBase
                            {...params}
                            placeholder="Buscar no sistema"
                            InputProps={{
                                ...params.InputProps,
                                inputRef: inputRef,
                                disableUnderline: true,
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //         <IconButton onClick={handleCollapse}>
                                //             <CloseIcon />
                                //         </IconButton>
                                //     </InputAdornment>
                                // ),
                                startAdornment: <SearchIcon />,
                            }}
                        />
                    )}
                />
            )}
        </Box>
    );
};

export default ExpandableSearch;