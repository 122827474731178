import {
  Bloco,
  CardPlanoSelecionado,
  ContainerBotoes,
  ContainerBotoesExcluir,
  ContainerConteudo,
  ContainerDoisInputs,
  ContainerEtapas,
  ContainerGlobal,
  ContainerInputs,
  ContainerTexto,
  ContainerTextoItemSelecionado,
  ContainerTitulo,
  ContainerTituloTooltip,
  Conteudo,
  ConteudoPopUp,
  Contrato,
  CustomTooltip,
  Especificacoes,
  EspecificacoesSenha,
  Etapa,
  HeaderPopup,
  ImagemWaybe,
  Separador,
  StyledAutocomplete,
  StyledHeader,
  TextoItemSelecionado,
  TextoItemSelecionadoExcluir,
  TextoTitulo,
  TextoTituloModal,
  WrapperContratos,
  XIcon,
} from "./styles";
import ImgWaybe from "../../../assets/images/waybe_logo.png";
import Switch from "../../../components/Switch";
import { useContext, useEffect, useRef, useState } from "react";
import { TemaCustomizadoContext } from "../../../contexts/theme";
import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import ImgCadastroFinalizadoLight from "../../../assets/images/cadastroFinalizadoLight.svg";
import ImgCadastroFinalizadoDark from "../../../assets/images/cadastroFinalizadoDark.svg";
import {
  BotaoAzulPreenchido,
  BotaoAzulSemFundo,
  BotaoAzulVazado,
  BotaoVerdePreenchido,
  BotaoVermelhoVazado,
} from "../../../components/Botao";
import {
  CampoDeDataSemHora,
  CampoDeSelecao,
  CampoDeSenha,
  CampoDeTexto,
} from "../../../components/CampoInput";
import { Controller, useForm, useWatch } from "react-hook-form";
import { date, number, object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatarCelularTempoReal } from "../../../utils/formatarCelularTempoReal";
import { validarEmail } from "../../../utils/validarEmail";
import { CircularProgress, TextField } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { formatarCPFTempoReal } from "../../../utils/formatarCPFTempoReal";
import { validarCPF } from "../../../utils/validarCPF";
import GerarModalResponsivo from "../../../components/GerarModalResponsivo";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import formatarReal from "../../../utils/formatarReal";
import { formatarCepNovo } from '../../../utils/formatarCepNovo'
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../../contexts/loader";
import useRecaptchaV3 from "../../../hooks/reCaptchaV3";
import { Question } from "phosphor-react";

export const Cadastro = ({ api, setDados, dados }) => {
  const { idIntegrador } = useParams();
  const { mudarTema, getTemaDarkAtivo } = useContext(TemaCustomizadoContext);
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const { abrirCarregamento, fecharCarregamento } = useLoader();
  const [cadastroIniciado, setCadastroIniciado] = useState(false);
  const [planoSelecionado, setPlanoSelecionado] = useState({});
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [carregandoCidades, setCarregandoCidades] = useState(false);
  const [listagemCidade, setListagemCidade] = useState([]);
  const [listagemUF, setListagemUF] = useState([]);
  const [abrirModalCadastroFinalizado, setAbrirModalCadastroFinalizado] =
    useState(false);
  const [abrirModalRecuperarDados, setAbrirModalRecuperarDados] =
    useState(false);
  const [dadosTodasEtapas, setDadosTodasEtapas] = useState();
  const [emailPessoal, setEmailPessoal] = useState("");
  const [planos, setPlanos] = useState([]);
  const executeRecaptcha = useRecaptchaV3(
    "6Lc5LR0qAAAAAFuUlbkKvsbLqtOQrRB9I_7wRFq8"
  );
  const navigate = useNavigate();
  const yupSchemaDadosEmpresa = object().shape({
    CPFECNPJ: string().required(),
    IEERG: string().required(),
    razaoSocialNome: string().required().nonNullable(),
    nomeFantasiaApelido: string(),
    urlAmigavel: string().required(),

    telefone: string(),
    celular: string()
      .required()
      .test((value) => validarQuantidadeCelular(value)),

    email: string()
      .required()
      .test((value) => validarEmail(value)),
    site: string(),

    CEP: string().required(),
    estado: string().required(),
    cidade: string().required(),
    logradouro: string(),
    Número: string().nullable(),
    bairro: string(),
    complemento: string(),
    pontoReferencia: string(),

    escolhaSegmento: string(),
    ondeConheceu: string(),
  });

  const {
    control,
    setError,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchemaDadosEmpresa),
    defaultValues: {
      CPFECNPJ: dados.cpfecnpj,
      email: dados.email,
      estado: "",
      cidade: "",
      nomeFantasiaApelido: "",
      razaoSocialNome: "",
      cep: "",
    },
  });
  const yupSchemaSeusDados = object().shape({
    nome: string().required(),
    cpf: string()
      .required()
      .test((value) => validarCPF(value)),
    dataNascimento: date()
      .required()
      .max(new Date(), "Não é possível incluir uma data futura"),
    genero: string(),

    telefone: string(),
    celular: string()
      .required()
      .test((value) => validarQuantidadeCelular(value)),
    email: string().test((value) =>
      cadastroIniciado ? value && value.length > 0 && validarEmail(value) : true
    ),
  });
  const {
    control: controlSeusDados,
    getValues: getValuesSeusDados,
    setValue: setValueSeusDados,
    handleSubmit: handleSubmitSeusDados,
    watch: watchSeusDados,
    reset: resetSeusDados,
    setError: setErrorSeusDados,
    clearErrors: clearErrorsSeusDados,
    formState: { errors: errorsSeusDados },
  } = useForm({
    resolver: yupResolver(yupSchemaSeusDados),
    defaultValues: {
      genero: "Selecione",
    },
  });

  function validarQuantidadeCelular(string) {
    if (string.length < 15) {
      return false;
    } else {
      return true;
    }
  }

  const yupSchemaDadosLogin = object().shape({
    usuario: string().required(),
    email: string().email().required(),
    senha: string()
      .required()
      .test((e) => validarSenha(e)),
    confirmarSenha: string()
      .required("Confirmação de senha é obrigatória.")
      .oneOf([ref("senha"), null], "As senhas não coincidem!"),
  });
  const {
    control: controlDadosLogin,
    getValues: getValuesDadosLogin,
    setValue: setValueDadosLogin,
    handleSubmit: handleSubmitDadosLogin,
    watch: watchDadosLogin,
    reset: resetDadosLogin,
    setError: setErrorDadosLogin,
    formState: { errors: errorsDadosLogin, isSubmitting },
  } = useForm({
    resolver: yupResolver(yupSchemaDadosLogin),
    defaultValues: {
      email: emailPessoal,
    },
  });

  useEffect(() => {
    const subscription = watchSeusDados((value, { name }) => {
      if (name === "email") {
        setEmailPessoal(value.email);
        setValueDadosLogin("email", value.email);
      }
    });
    return () => subscription.unsubscribe();
  }, [watchSeusDados, setValueDadosLogin]);

  useEffect(() => {
    if (paginaAtual === 3) {
      let emailSeusDados = getValuesSeusDados("email");
      let emailEmpresa = getValues("email");
      let erroEmailSeusDados = !validarEmail(emailSeusDados);

      if (!emailSeusDados || emailSeusDados == "" || erroEmailSeusDados) {
        setValueDadosLogin("email", emailEmpresa);
      }
    }
  }, [paginaAtual]);

  useEffect(() => {
    buscarDadosAnteriores();
  }, []);

  useEffect(() => {
    console.log(cadastroIniciado, "cadastroIniciado");
  }, [cadastroIniciado]);

  async function buscarTodasUFs() {
    try {
      const res = await api({
        method: "get",
        url: `/waybe/cep/buscar-todas/unidades-federativas?size=999`,
      });

      setListagemUF(res.data.content);
    } catch (e) {
      console.error(e);
    } finally {
      setCarregandoCidades(false);
    }
  }

  async function onHandleSubmit(dados) {
    abrirCarregamento();

    try {
      const idEmpresa = await inserirEmpresa(dados);
      const idContrato = await inserirContrato(idEmpresa);
      setDadosTodasEtapas({
        ...dadosTodasEtapas,
        dadosDaEmpresa: dados,
        idEmpresa,
        idContrato,
      });
      setPaginaAtual(2);
      largura < 768 &&
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    } catch (error) {
      toast.error("Houve um erro ao cadastrar a empresa, tente novamente!");
      console.error(error);
    } finally {
      fecharCarregamento();
    }
  }

  function decodeString(str) {
    try {
      let urlDecodificada = decodeURIComponent(
        escape(
          String(str)
            .normalize("NFD")
            .replace(/\p{Mn}/gu, "")
            .toLowerCase()
        )
      );
      return urlDecodificada;
    } catch (error) {
      console.error(error, str);
      return String(str).toLowerCase();
    }
  }

  async function buscarCidades(estado, cidadeDefault = null) {
    setValue("cidade", "");
    setListagemCidade([]);
    if (!estado) {
      return;
    }
    setCarregandoCidades(true);
    try {
      const res = await api({
        method: "get",
        url: `/waybe/cep/buscar-todas-cidades/por-uf?uf=${estado}&size=9999`,
      });

      let listagemCidade = res.data.content ? res.data.content : [];
      setListagemCidade(listagemCidade);
      if (cidadeDefault) {
        let stringCidadeTratada = decodeString(cidadeDefault);

        let cidadeEncontrada = listagemCidade.find((item) => {
          const itemNome = decodeString(item.nome);
          const cidadeTratada = decodeString(stringCidadeTratada);
          return itemNome === cidadeTratada;
        });

        setValue(
          "cidade",
          cidadeEncontrada ? cidadeEncontrada.nome : cidadeDefault
        );
      }
      return res.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      setCarregandoCidades(false);
    }
  }

  function onHandleSubmitSeusDados(dados) {
    setPaginaAtual(3);
    setDadosTodasEtapas({ ...dadosTodasEtapas, seusDados: dados });
    largura < 768 &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }
  async function onHandleSubmitDadosLogin(dados) {
    abrirCarregamento();
    try {
      const recaptchaToken = await executeRecaptcha("login");

      const response = await verificarCaptcha(recaptchaToken);

      const dadosTodasTelas = {
        dadosLogin: { ...dados },
        dadosDaEmpresa: { ...getValues() },
        seusDados: { ...getValuesSeusDados() },
        planoSelecionado,
        idEmpresa: dadosTodasEtapas.idEmpresa,
        idContrato: dadosTodasEtapas.idContrato,
      };

      if (response) {
        executarProcessosDeCadastro(dadosTodasTelas);
      } else {
        fecharCarregamento();
        toast.error("Falha na validação do captcha, tente novamente.");
      }
    } catch (error) {
      toast.error("Falha na validação do captcha, tente novamente.");
      console.error(error);
      fecharCarregamento();
    }
  }

  async function verificarCaptcha(token) {
    try {
      const res = await api(
        `/usuario-sessao/captcha/validate?g-recaptcha-response=${token}`
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  const watchedCep = watch("CEP"); // you can also target specific fields by their names
  useEffect(() => {
    if (watchedCep?.length === 9) {
      preenchimentoAutomaticoCep(watchedCep);
    }
  }, [watchedCep]);

  async function preenchimentoAutomaticoCep(cep) {
    if (!cep) {
      return;
    }
    let cepLimpo = cep.replace(/\D/g, "");

    if (cepLimpo?.length === 8) {
      try {
        const dadosLogradouro = await api({
          method: "get",
          url: `/waybe/cep/buscar-logradouro/${cepLimpo}`,
        });

        if (dadosLogradouro.status == 200 && dadosLogradouro?.data?.nome) {
          setValue("logradouro", dadosLogradouro.data.nome);

          const dadosBairro = await api({
            method: "get",
            url: `/waybe/cep/buscar-bairro/${dadosLogradouro.data.idBairro}`,
          });

          if (dadosBairro.status == 200 && dadosBairro?.data?.nome) {
            setValue("bairro", dadosBairro.data.nome);

            const dadosCidade = await api({
              method: "get",
              url: `/waybe/cep/buscar-cidade/${dadosBairro.data.idCidade}`,
            });
            if (dadosCidade.status == 200 && dadosCidade?.data?.nome) {
              setValue("estado", dadosCidade.data.unidadeFederativa);
              buscarCidades(
                dadosCidade.data.unidadeFederativa,
                dadosCidade.data.nome
              );
            }
          }
        }
      } catch (error) {
        console.error("Erro ao buscar dados do CEP:", error);
      }
    }
  }

  const validarSenha = (senha) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(senha);
  };

  const ModalConfirmar = ({ close }) => {
    return (
      <ConteudoPopUp>
        <HeaderPopup>
          <TextoTitulo>Recuperação de dados</TextoTitulo>
          <XIcon onClick={close} />
        </HeaderPopup>
        <ContainerTextoItemSelecionado>
          <TextoItemSelecionadoExcluir>
            Você começou um cadastro anteriormente e não o finalizou. Deseja
            recuperá-lo?
          </TextoItemSelecionadoExcluir>
        </ContainerTextoItemSelecionado>

        <ContainerBotoesExcluir>
          <BotaoVermelhoVazado
            texto="Não"
            onClick={() => {
              close();
              setDadosTodasEtapas({
                dadosIniciais: dados.cpfecnpj,
              });
              localStorage.setItem(
                "DADOS-CADASTRO-WAYMENU-V1.0",
                JSON.stringify({
                  dadosIniciais: dados.cpfecnpj,
                })
              );
            }}
            tamanho="SM"
          />
          <BotaoAzulPreenchido
            onClick={() => {
              setarDadosRecuperados();
              close();
            }}
            texto="Sim"
            tamanho="SM"
          />
        </ContainerBotoesExcluir>
      </ConteudoPopUp>
    );
  };

  const ModalCadastroFinalizado = () => {
    return (
      <ConteudoPopUp>
        <HeaderPopup>
          <TextoTituloModal>Cadastro Finalizado!</TextoTituloModal>
        </HeaderPopup>
        <ContainerTexto>
          <TextoItemSelecionado>
            Parabéns {getValuesSeusDados("nome")}!!
          </TextoItemSelecionado>
          <TextoItemSelecionado>
            Em breve, você receberá um e-mail com os links para baixar os
            instaladores.
          </TextoItemSelecionado>
        </ContainerTexto>
        <img
          src={
            getTemaDarkAtivo
              ? ImgCadastroFinalizadoDark
              : ImgCadastroFinalizadoLight
          }
          style={{ width: "100%" }}
        />
        <ContainerBotoesExcluir>
          <BotaoAzulPreenchido
            onClick={() => {
              navigate("/login");
            }}
            texto="Acessar ERP"
            tamanho="MD"
          />
        </ContainerBotoesExcluir>
      </ConteudoPopUp>
    );
  };

  function buscarDadosAnteriores() {
    if (dados.cadastroIniciadoDados) {
      let idEmpresaRecuperado =
        dados.cadastroIniciadoDados.controleCadastro.idEmpresaCadastro;
      let idContratoRecuperado =
        dados.cadastroIniciadoDados.idEmpresaContratoWaybe;
      let cadastroIniciadoRecuperado = dados.cadastroIniciado;
      if (idEmpresaRecuperado && idContratoRecuperado) {
        setPaginaAtual(2);
        setCadastroIniciado(cadastroIniciadoRecuperado ? true : false);
        toast.success("O cadastro da empresa foi recuperado com sucesso!");
        setDadosTodasEtapas({
          ...dadosTodasEtapas,
          idEmpresa: idEmpresaRecuperado,
          idContrato: idContratoRecuperado,
          cadastroIniciado: cadastroIniciadoRecuperado,
        });
      } else {
        toast.error(
          "Problemas ao recuperar os dados de um cadastro já iniciado."
        );
        setDadosTodasEtapas({
          dadosIniciais: dados.cpfecnpj,
        });
      }
    } else {
      let dadosLocalStorage;
      try {
        dadosLocalStorage = JSON.parse(
          localStorage.getItem("DADOS-CADASTRO-WAYMENU-V1.0")
        );
      } catch (error) {
        console.log(error);

        localStorage.clear("DADOS-CADASTRO-WAYMENU-V1.0");
        localStorage.setItem(
          "DADOS-CADASTRO-WAYMENU-V1.0",
          JSON.stringify({
            dadosIniciais: dados.cpfecnpj,
          })
        );
        setDadosTodasEtapas({
          dadosIniciais: dados.cpfecnpj,
        });
      }

      if (
        dadosLocalStorage &&
        dadosLocalStorage.dadosIniciais === dados.cpfecnpj &&
        Object.keys(dadosLocalStorage).length > 1
      ) {
        setAbrirModalRecuperarDados(true);
        setDadosTodasEtapas(dadosLocalStorage);
        console.log("D");
      } else {
        localStorage.clear("DADOS-CADASTRO-WAYMENU-V1.0");
        localStorage.setItem(
          "DADOS-CADASTRO-WAYMENU-V1.0",
          JSON.stringify({
            dadosIniciais: dados.cpfecnpj,
          })
        );
        setDadosTodasEtapas({
          dadosIniciais: dados.cpfecnpj,
        });
      }
    }
  }
  function setarDadosRecuperados() {
    try {
      let dadosLocalStorage = JSON.parse(
        localStorage.getItem("DADOS-CADASTRO-WAYMENU-V1.0")
      );
      setDadosTodasEtapas(dadosLocalStorage);
      console.log(dadosLocalStorage);

      if (dadosLocalStorage.planoSelecionado) {
        setPaginaAtual(1);
      }

      if (dadosLocalStorage.idEmpresa) {
        setPaginaAtual(2);
      }

      setPlanoSelecionado(dadosLocalStorage.planoSelecionado);
      dadosLocalStorage.dadosDaEmpresa &&
        Object.entries(dadosLocalStorage.dadosDaEmpresa).forEach(
          async (item) => {
            if (item[0] == "CPFECNPJ") {
              return;
            }

            if (item[0] == "cidade") {
              const cidades = await buscarCidades(getValues("estado"));
            }
            setValue(item[0], item[1]);
          }
        );

      dadosLocalStorage.seusDados &&
        Object.entries(dadosLocalStorage.seusDados).forEach(async (item) => {
          // if (item[0] == "estado") {
          //   await buscarCidadesSeusDados(item[1]);
          // }
          // if (item[0] == "cidade") {
          //   const cidades = await buscarCidadesSeusDados(
          //     getValuesSeusDados("estado")
          //   );
          // }

          if (item[0] == "dataNascimento") {
            setValueSeusDados(item[0], dayjs(item[1]));
            return;
          }

          setValueSeusDados(item[0], item[1]);
        });

      toast.success("Dados recuperados com sucesso!");
    } catch (error) {
      console.error(error);
      toast.error("Houve um erro ao recuperar os dados");
    }
  }

  async function identificarErros({ stringErro, dadosEmpresa = false }) {
    // console.log(stringErro, dadosEmpresa, "stringErro");

    try {
      if (!stringErro) {
        return;
      }

      let string = stringErro.toLowerCase();

      let pagina = paginaAtual;

      if (
        string.includes("ieerg") ||
        string.includes("ie") ||
        string.includes("rg")
      ) {
        setError("IEERG", { type: "custom", message: stringErro });
        if (pagina > 1) {
          pagina = 1;
        }
      }

      if (string.includes("nome fantasia") || string.includes("apelido")) {
        setError("nomeFantasiaApelido", {
          type: "custom",
          message: stringErro,
        });
        if (pagina > 1) {
          pagina = 1;
        }
      }

      if (string.includes("url amigável")) {
        setError("urlAmigavel", { type: "custom", message: stringErro });
        if (pagina > 1) {
          pagina = 1;
        }
      }

      if (string.includes("email") || string.includes("e-mail")) {
        if (dadosEmpresa) {
          toast.error("O endereço de e-mail deve ser válido.");
          setError("email", { type: "custom", message: stringErro });
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (string.includes("data de nascimento")) {
        setErrorSeusDados("dataNascimento", {
          type: "custom",
          message: stringErro,
        });
        if (pagina > 2) {
          pagina = 2;
        }
      }

      if (
        string.includes("celular") ||
        string.includes("telefone") ||
        string.includes("celulares")
      ) {
        if (dadosEmpresa) {
          setError("telefone", { type: "custom", message: stringErro });
          toast.error("Por favor, informe um telefone válido.");
          if (pagina > 1) {
            pagina = 1;
          }
        } else {
          setErrorSeusDados("celular", { type: "custom", message: stringErro });
          if (pagina > 2) {
            pagina = 2;
          }
        }
      }

      if (string.includes("cep")) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe um CEP válido.");
          setError("CEP", { type: "custom", message: stringErro });
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (string.includes("estado")) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe um estado válido.");
          setError("estado", { type: "custom", message: stringErro });
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (string.includes("cidade")) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe uma cidade válida.");
          setError("cidade", { type: "custom", message: stringErro });
          setValue("cidade", "");
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (string.includes("logradouro")) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe um logradouro válido.");
          setError("logradouro", { type: "custom", message: stringErro });
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (string.includes("número")) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe um número válido.");
          setError("Número", { type: "custom", message: stringErro });
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (string.includes("bairro")) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe um bairro válido.");
          setError("bairro", { type: "custom", message: stringErro });
          if (pagina > 1) {
            pagina = 1;
          }
        }
      }

      if (
        string.includes("cpf") ||
        string.includes("cnpj") ||
        string.includes("cpfecnpj")
      ) {
        if (dadosEmpresa) {
          toast.error("Por favor, informe um CPF/CNPJ válido.");
          setError("CPFECNPJ", {
            type: "custom",
            message: stringErro,
          });
          if (pagina > 1) {
            pagina = 1;
          }
        } else {
          setErrorSeusDados("cpf", { type: "custom", message: stringErro });
          if (pagina > 2) {
            pagina = 2;
          }
        }
      }

      // if () {
      //   setErrorSeusDados("nome", { type: "custom", message: stringErro });
      //   if (pagina > 2) {
      //     pagina = 2;
      //   }
      // }

      if (string.includes("o username informado já existe")) {
        setErrorSeusDados("nome", {
          type: "custom",
          message: "Já existe um usuário com esse Nome de usuário.",
        });
        if (pagina > 2) {
          pagina = 2;
        }
      }

      if (string.includes("já existe um usuário com esse username.")) {
        setErrorDadosLogin("usuario", {
          type: "custom",
          message: "Já existe um usuário com esse username.",
        });
      }

      if (string.includes("já existe um usuário com esse e-mail")) {
        setErrorSeusDados("email", { type: "custom", message: stringErro });
        if (pagina > 2) {
          pagina = 2;
        }
      }

      if (string.includes("senha")) {
        setErrorDadosLogin("senha", { type: "custom", message: stringErro });
        setErrorDadosLogin("confirmarSenha", {
          type: "custom",
          message: stringErro,
        });
      }

      setPaginaAtual((paginaAtual) =>
        paginaAtual > pagina ? pagina : paginaAtual
      );
    } catch (error) {
      console.error("Erro no tratamento de erros:", error);
    }
  }

  async function buscarDadosCNPJ(cnpj) {
    const cnpjLimpo = cnpj.replace(/[^0-9]/g, "");

    if (cnpjLimpo.length < 14) {
      return;
    }
    try {
      const res = await fetch(
        `https://brasilapi.com.br/api/cnpj/v1/${cnpjLimpo}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (res.status == 200) {
        setValue("estado", data.uf);
        await buscarCidades(data.uf, data.municipio);
        setValue("logradouro", data.logradouro);
        setValue("bairro", data.bairro);
        setValue("Número", data.numero);
        setValue("complemento", data.complemento);
        setValue("CEP", data.cep.replace(/(\d{5})(\d{3})/, "$1-$2 "));
        setValue("razaoSocialNome", data.razao_social);
        if (!data.nome_fantasia) {
          setValue("nomeFantasiaApelido", data.razao_social);
        } else {
          setValue("nomeFantasiaApelido", data.nome_fantasia);
        }
      }
      // encontrarCidade(data.municipio);
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  }

  // function encontrarCidade(cidade) {
  //   console.log(cidade, "cidade");
  //   console.log(listagemCidade, "listagemCidade");
  //   const result = listagemCidade.find(
  //     (item) => item.nome.toUpperCase() === cidade
  //   );
  //   console.log(result, "result");

  //   if (Object.keys(result).length > 0) {
  //     setValue("cidade", result.nome);
  //   }
  // }
  async function fetchPlanos() {
    try {
      const res = await api(`/contrato/wizard/planos-waymenu/${idIntegrador}`);
      setPlanos(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function inserirEmpresa(dados) {
    try {
      let urlAmigavel = getValues("urlAmigavel");
      const res = await api.post(
        `/configuracoes-lojas/wizard/empresa/inserir/${idIntegrador}`,
        {
          "urlAmigavel": urlAmigavel,
          "cadastroPessoaDTO": {
            "pessoa": {
              "nome": dados.razaoSocialNome?.trim(),
              "apelido": dados.nomeFantasiaApelido?.trim(),
              "inscricaoFederal": dados.CPFECNPJ,
              "inscricaoEstadual": dados.IEERG,
              "email": dados.email,
              "site": dados.site?.trim(),
              "tipoPessoa": "JURIDICA",
              "pessoaJuridica": {},
              "pessoaFisica": {},
            },

            "endereco": {
              "descricao": "Endereço Principal",
              "logradouro": dados.logradouro,
              "numero": dados.Número,
              "complemento": dados.complemento,
              "bairro": dados.bairro,
              "cep": formatarCepNovo(dados.CEP),
              "pontoReferencia": dados.pontoReferencia,
              "idCidade": listagemCidade.find(
                (item) => decodeString(item.nome) === decodeString(dados.cidade)
              )?.id,
              "ativo": true,
            },
            "telefones": [
              ...(dados.telefone
                ? [
                  {
                    "descricao": "TELEFONE",
                    "tipo": "RESIDENCIAL",
                    "telefone": dados.telefone,
                  },
                ]
                : []),
              {
                "descricao": "CELULAR",
                "tipo": "CELULAR",
                "telefone": dados.celular,
              },
            ],
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        let message = String(error?.response?.data?.message);
        let erros = message.split("\n");

        erros.forEach((item) => {
          identificarErros({ stringErro: item, dadosEmpresa: true });
        });
      }
      // else if (typeof error?.response?.data === "object") {
      //   Object.values(error?.response?.data).forEach((item) => {
      //     identificarErros({ stringErro: item, dadosEmpresa: true });
      //   });
      //   toast.error(
      //     item
      //       ? item
      //       : "Houve um erro ao cadastrar a empresa, tente novamente mais tarde!"
      //   );
      // }
      throw error;
    }
  }
  async function atualizarEmpresa(idEmpresa, idContrato) {
    try {
      const res = await api.put(`configuracoes-lojas/empresa/${idEmpresa}`, {
        idEmpresaContratoWaybe: idContrato,
      });
    } catch (error) {
      console.error(error);
      identificarErros({ stringErro: error?.response?.data?.message });
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Houve um erro ao cadastrar a empresa, tente novamente mais tarde!"
      );
    }
  }
  async function inserirContrato(idEmpresa) {
    const res = await api
      .post(`/contrato/wizard/waybe/empresa-contrato/nova-empresa`, {
        idEmpresa,
        "idPlanoPadraoWaymenu": planoSelecionado.id,
        "tipoRecorrenciaPlano": "MENSAL",
        idIntegrador,
      })
      .catch((error) => {
        console.error(error);
        identificarErros({ stringErro: error?.response?.data?.message });
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Houve um erro ao  o contrato, tente novamente mais tarde!"
        );
      });
    return res.data;
  }
  async function cadastrarUsuario(idEmpresa, dados) {
    const telefone = dados.seusDados.celular.replace(/\D+/g, "");

    return await api
      .post(`usuario-sessao/wizard/usuario`, {
        "nome": dados.seusDados.nome,
        "apelido": dados.seusDados.nome,
        "idEmpresa": idEmpresa,
        "cpf": dados.seusDados.cpf,
        "email": dados.seusDados.email
          ? dados.seusDados?.email
          : dados.dadosDaEmpresa?.email,
        "telefone": telefone,
        "username": dados.dadosLogin.usuario,
        "password": dados.dadosLogin.senha,
        "genero":
          dados.seusDados.genero !== "Selecione"
            ? dados.seusDados.genero.toUpperCase()
            : null,
        "dataNascimento": dados.seusDados.dataNascimento,
      })
      .catch((error) => {
        console.log(error);

        identificarErros({
          stringErro: error?.response?.data?.message || error?.response?.data,
        });
        if (typeof error?.response?.data === "string") {
          toast.error(error?.response?.data);
        } else {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Houve um erro ao cadastrar o usuário, tente novamente mais tarde!"
          );
        }
        fecharCarregamento();
      });
  }

  async function executarProcessosDeCadastro(dados) {
    try {
      const idEmpresa = dados.idEmpresa;
      const idContrato = dados.idContrato;

      //inserirContrato(idEmpresa),

      await cadastrarUsuario(idEmpresa, dados).then((response) => {
        console.log(response);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          atualizarEmpresa(idEmpresa, idContrato);
          setAbrirModalCadastroFinalizado(true);
          localStorage.clear("DADOS-CADASTRO-WAYMENU-V1.0");
        } else {
          throw new Error("Falha ao cadastrar o Usuário");
        }
      });
    } catch (error) {
      console.log(error);
      console.error("Um erro ocorreu durante o processamento", error);
    } finally {
      fecharCarregamento();
    }
  }

  const [botaoHabilitado, setBotaoHabilitado] = useState(false);

  useEffect(() => {
    buscarTodasUFs();
    buscarDadosCNPJ(dados.cpfecnpj);
    fetchPlanos();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (
        Object.keys(errors).length == 1 &&
        errors?.celular?.type == "custom"
      ) {
        return;
      }

      toast.error("Preencha todos os campos obrigatórios!");
      return;
    }
    if (errorsSeusDados.cpf) {
      toast.error("CPF inválido!");
    }
    if (Object.keys(errorsSeusDados).length > 0) {
      if (
        Object.keys(errorsSeusDados).length == 1 &&
        errorsSeusDados?.celular?.type == "custom"
      ) {
        return;
      }

      toast.error("Preencha todos os campos obrigatórios!");
      return;
    }
    if (Object.keys(errorsDadosLogin).length > 0) {
      toast.error("Preencha todos os campos obrigatórios!");
      return;
    }
  }, [errors, errorsSeusDados, errorsDadosLogin]);

  useEffect(() => {
    localStorage.setItem(
      "DADOS-CADASTRO-WAYMENU-V1.0",
      JSON.stringify(dadosTodasEtapas)
    );
  }, [dadosTodasEtapas]);

  return (
    <>
      <ContainerGlobal>
        <StyledHeader>
          <div>
            <TextoTitulo>Boas Vindas ao</TextoTitulo>
            <ImagemWaybe url={ImgWaybe} />
          </div>

          {paginaAtual !== 0 && largura > 768 && (
            <CardPlanoSelecionado>
              <div>
                <p>Plano Selecionado:</p>
                {planoSelecionado?.descricao?.toLowerCase() == "grátis" ? (
                  <p>100% grátis</p>
                ) : (
                  <p>Teste grátis por 7 dias</p>
                )}
              </div>
              <div>
                <h1>{planoSelecionado.descricao}</h1>
                <h1>{formatarReal(planoSelecionado.valorMensal)}/mês</h1>
                <BotaoAzulSemFundo
                  texto="Alterar plano"
                  onClick={() => setPaginaAtual(0)}
                />
              </div>
            </CardPlanoSelecionado>
          )}
          <Switch
            onClick={() => {
              mudarTema();
            }}
            checked={!theme.temaDarkAtivo}
            valor={!theme.temaDarkAtivo}
            style={largura < 768 && { position: "absolute", top: 10, right: 0 }}
            mudarTema
          />
        </StyledHeader>
        <ContainerConteudo>
          <Conteudo>
            <ContainerEtapas>
              <Etapa atual={paginaAtual === 0 || paginaAtual > 0}>
                <span>
                  {paginaAtual > 0 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    1
                  )}
                </span>
                {largura > 768 && <p>Escolha do plano</p>}
                {largura < 768 && paginaAtual == 0 && <p>Escolha do plano</p>}
              </Etapa>
              <Separador />
              <Etapa atual={paginaAtual === 1 || paginaAtual > 1}>
                <span>
                  {paginaAtual > 1 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    2
                  )}
                </span>
                {largura > 768 && <p>Dados da empresa</p>}
                {largura < 768 && paginaAtual == 1 && <p>Dados da empresa</p>}
              </Etapa>
              <Separador />
              <Etapa atual={paginaAtual === 2 || paginaAtual > 2}>
                <span>
                  {paginaAtual > 2 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    3
                  )}
                </span>
                {largura > 768 && <p>Seus dados</p>}
                {largura < 768 && paginaAtual == 2 && <p>Seus dados</p>}
              </Etapa>
              <Separador />
              <Etapa atual={paginaAtual === 3 || paginaAtual > 3}>
                <span>
                  {paginaAtual > 3 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    4
                  )}
                </span>
                {largura > 768 && <p>Informações de acesso</p>}
                {largura < 768 && paginaAtual == 3 && <p>Inform. de acesso</p>}
              </Etapa>
            </ContainerEtapas>

            {paginaAtual === 0 && (
              <>
                <WrapperContratos>
                  {planos.map((item) => (
                    <Contrato
                      key={item.id}
                      onClick={() => {
                        setPlanoSelecionado(item);
                      }}
                      selecionado={planoSelecionado.id === item.id}
                    >
                      <h1>{item.descricao}</h1>
                      <span>{formatarReal(item.valorMensal)}</span>
                      <p>Por mês</p>
                      {item.descricao.toLowerCase() == "grátis" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos até 50 produtos</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber até R$5.000 por mês</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 1 conexão ativa</p>
                            </div>
                          </Especificacoes>
                          <p>100% Grátis</p> */}
                          {/* <ChipRecomendado>Recomendado</ChipRecomendado> */}
                        </>
                      )}
                      {item.descricao.toLowerCase() == "bronze" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 2 conexões ativas</p>
                            </div>
                          </Especificacoes>
                          <p>Teste grátis por 7 dias</p>
                          <ChipRecomendado>Recomendado</ChipRecomendado> */}
                        </>
                      )}
                      {item.descricao.toLowerCase() == "prata" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 3 conexões ativas</p>
                            </div>
                          </Especificacoes>
                          <p>Teste grátis por 7 dias</p> */}
                        </>
                      )}
                      {item.descricao.toLowerCase() == "ouro" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 4 conexões ativas</p>
                            </div>
                          </Especificacoes>
                          <p>Teste grátis por 7 dias</p> */}
                        </>
                      )}
                    </Contrato>
                  ))}
                </WrapperContratos>

                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => window.location.reload()}
                  />
                  <BotaoAzulPreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Próximo"
                    onClick={() => {
                      if (planoSelecionado.id) {
                        setPaginaAtual(1);
                        setDadosTodasEtapas({
                          ...dadosTodasEtapas,
                          planoSelecionado: planoSelecionado,
                        });
                        largura < 768 &&
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                      } else {
                        toast.error(
                          "Selecione ao menos um plano para continuar!"
                        );
                      }
                    }}
                  />
                </ContainerBotoes>
              </>
            )}
            {paginaAtual === 1 && (
              <>
                <ContainerTitulo>
                  <h1>Informações gerais</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={control}
                    name="CPFECNPJ"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={dados.cpfecnpj.length === 18 ? "CNPJ" : "CPF"}
                        value={field.value}
                        onChange={field.onChange}
                        disabled
                        error={errors.CPFECNPJ}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="IEERG"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={dados.cpfecnpj.length === 18 ? "IE" : "RG"}
                        value={field.value}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\s+/g, "");
                          if (e.target.value.length < 20) {
                            field.onChange(value);
                          }
                        }}
                        required
                        error={errors.IEERG}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="razaoSocialNome"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={
                          dados.cpfecnpj.length === 18 ? "Razão social" : "Nome"
                        }
                        value={field.value}
                        onChange={(e) => {
                          if (
                            e.target.value.length <
                            (dados.cpfecnpj.length === 18 ? 100 : 50)
                          ) {
                            let valor = e.target.value.replace(/\s{2,}/g, " ");
                            valor = valor == " " || !valor ? "" : valor;
                            field.onChange(valor);
                            setValue("nomeFantasiaApelido", valor);
                          }
                        }}
                        defaultValue={""}
                        required
                        error={errors.razaoSocialNome}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="nomeFantasiaApelido"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={
                          dados.cpfecnpj.length === 18
                            ? "Nome fantasia"
                            : "Apelido"
                        }
                        value={field.value}
                        onChange={(e) => {
                          let valor = e.target.value.replace(/\s{2,}/g, " ");
                          valor = valor == " " || !valor ? "" : valor;
                          field.onChange(valor);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="urlAmigavel"
                    render={({ field }) => (
                      // <CampoDeTexto
                      //   titulo={"URL Amigável"}
                      //   value={field.value}
                      //   onChange={field.onChange}
                      //   error={errors.urlAmigavel}
                      // />
                      <CampoDeTexto
                        // InputLabelProps={{ style: { pointerEvents: "auto" } }}
                        InputProps={{
                          endAdornment: (
                            <CustomTooltip
                              title={
                                "Insira uma URL personalizada para sua empresa, sem espaços ou caracteres especiais, que será utilizada pelos seus consumidores para acessar o seu cardápio. \nExemplo: 'minha-empresa'"
                              }
                              placement="top"
                              arrow
                            >
                              <Question
                                color={theme.cores.brand40Default}
                                size={16}
                                weight="bold"
                              />
                            </CustomTooltip>
                          ),
                        }}
                        value={field.value}
                        onChange={(e) => {
                          const value = e.target.value.replace(
                            /[^a-zA-Z0-9-_]/g,
                            ""
                          );
                          field.onChange(value);
                        }}
                        error={errors.urlAmigavel}
                        titulo="URL Amigável"
                        required
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Dados de contato</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={control}
                    name="telefone"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Telefone"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="celular"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Celular"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                          if (!validarQuantidadeCelular(e.target.value)) {
                            setError("celular", {
                              type: "custom",
                              message: "O Celular precisa ter 11 digitos.",
                            });
                          } else {
                            clearErrors("celular");
                          }
                        }}
                        error={errors.celular}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="E-mail"
                        value={field.value}
                        required
                        onChange={(e) => {
                          const value = e.target.value.replace(/\s+/g, "");
                          field.onChange(value);
                        }}
                        error={errors.email}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="site"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Site"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Endereço</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={control}
                    name="CEP"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="CEP"
                        // autoComplete="off"
                        required
                        value={field.value}
                        onChange={(e) => {
                          if (formatarCepNovo(e.target.value)) {
                            field.onChange(formatarCepNovo(e.target.value));
                          }
                        }}
                        error={errors.CEP}
                      />
                    )}
                  />
                  <Controller
                    name="estado"
                    control={control}
                    render={({ field }) => (
                      <StyledAutocomplete
                        disablePortal
                        autoSelect={false}
                        inputValue={field.value ? field.value : ""}
                        onChange={(e, value) => {
                          field?.onChange(value ? value : "");
                          buscarCidades(value ? value : "");
                        }}
                        value={field.value ? field.value : ""}
                        options={
                          listagemUF?.length
                            ? listagemUF?.map((item) => item.uf)
                            : []
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.estado}
                            label="Estado"
                            required
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="cidade"
                    control={control}
                    render={({ field }) => (
                      <StyledAutocomplete
                        disablePortal
                        loading={carregandoCidades}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                        disabled={!listagemCidade.length}
                        options={
                          listagemCidade
                            ? listagemCidade.map((item) => item.nome)
                            : []
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cidade"
                            error={errors.cidade}
                            required
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {carregandoCidades ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="logradouro"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Logradouro"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="Número"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Número"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="bairro"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Bairro"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="complemento"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Complemento"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="pontoReferencia"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Ponto de referência"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.target.blur();
                          }
                        }}
                      />
                    )}
                  />
                </ContainerInputs>
                {/* <ContainerTitulo>
                                <h1>Negócio</h1>
                                <Separador />
                            </ContainerTitulo>
                            <ContainerDoisInputs>
                                <Controller
                                    control={control}
                                    name="escolhaSegmento"
                                    render={({ field }) => (
                                        <CampoDeTexto
                                            titulo="Escolha o segmento do seu negócio"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="ondeConheceu"
                                    render={({ field }) => (
                                        <CampoDeTexto
                                            titulo="Por onde nos conheceu?"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </ContainerDoisInputs> */}

                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => setPaginaAtual(0)}
                  />
                  <BotaoAzulPreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Próximo"
                    onClick={handleSubmit(onHandleSubmit)}
                  />
                </ContainerBotoes>
              </>
            )}
            {paginaAtual === 2 && (
              <>
                <ContainerTitulo>
                  <h1>Informações gerais</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={controlSeusDados}
                    name="nome"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Nome completo"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                        error={errorsSeusDados.nome}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="cpf"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="CPF"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCPFTempoReal(e));
                        }}
                        error={errorsSeusDados.cpf}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="dataNascimento"
                    render={({ field }) => (
                      <CampoDeDataSemHora
                        titulo="Data de nascimento *"
                        value={field.value}
                        onChange={field.onChange}
                        required
                        error={errorsSeusDados.dataNascimento}
                        maxDate={dayjs()}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="genero"
                    render={({ field }) => (
                      <CampoDeSelecao
                        titulo="Gênero"
                        valor={field.value}
                        onChange={field.onChange}
                        campoVazio={false}
                        items={["Selecione", "Feminino", "Masculino", "Outros"]}
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Dados de contato</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={controlSeusDados}
                    name="celular"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Celular"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                          if (!validarQuantidadeCelular(e.target.value)) {
                            setErrorSeusDados("celular", {
                              type: "custom",
                              message: "O Celular precisa ter 11 digitos.",
                            });
                          } else {
                            clearErrorsSeusDados("celular");
                          }
                        }}
                        error={errorsSeusDados.celular}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="email"
                    render={({ field }) => (
                      <CampoDeTexto
                        required={cadastroIniciado ? true : false}
                        titulo="E-mail"
                        value={field.value}
                        onChange={field.onChange}
                        error={errorsSeusDados.email}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.target.blur();
                          }
                        }}
                      />
                    )}
                  />
                  <div />
                  <div />
                </ContainerInputs>

                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    disabled={
                      dadosTodasEtapas?.idEmpresa || cadastroIniciado
                        ? true
                        : false
                    }
                    texto="Voltar"
                    onClick={() => setPaginaAtual(1)}
                  />
                  <BotaoAzulPreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Próximo"
                    onClick={handleSubmitSeusDados(onHandleSubmitSeusDados)}
                  />
                </ContainerBotoes>
              </>
            )}
            {paginaAtual === 3 && (
              <>
                <ContainerTitulo>
                  <h1>Login</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerDoisInputs>
                  <Controller
                    control={controlDadosLogin}
                    name="usuario"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Usuário"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            // if (e.target.value === "") {
                            //   field.onChange(e);
                            // }
                            // if (RegExp().test(e.target.value)) {
                            //   field.onChange(e);
                            // }
                            const value = e.target.value.replace(/\s+/g, "");
                            if (value.length < 100) {
                              setValueDadosLogin("usuario", value);
                            }
                          }
                        }}
                        required
                        error={errorsDadosLogin.usuario}
                      />
                    )}
                  />
                  <Controller
                    control={controlDadosLogin}
                    name="email"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="E-mail"
                        value={field.value}
                        onChange={field.onChange}
                        error={errorsDadosLogin.email}
                        required
                        disabled
                      />
                    )}
                  />
                </ContainerDoisInputs>
                <ContainerTitulo>
                  <h1>Senha</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerDoisInputs>
                  <Controller
                    control={controlDadosLogin}
                    name="senha"
                    render={({ field }) => (
                      <CampoDeSenha
                        titulo="Senha"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            field.onChange(e);
                          } else if (RegExp(/^[^\s]+$/).test(e.target.value)) {
                            field.onChange(e);
                          }
                        }}
                        error={errorsDadosLogin.senha}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlDadosLogin}
                    name="confirmarSenha"
                    render={({ field }) => (
                      <Bloco>
                        <CampoDeSenha
                          titulo="Confirmar senha"
                          value={field.value}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              field.onChange(e);
                            } else if (
                              RegExp(/^[^\s]+$/).test(e.target.value)
                            ) {
                              field.onChange(e);
                            }
                          }}
                          required
                          error={
                            errorsDadosLogin.senha ||
                            watchDadosLogin("senha") !== field.value
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              e.target.blur();
                            }
                          }}
                        />
                        {watchDadosLogin("senha") !== field.value && (
                          <p>As senhas não coincidem!</p>
                        )}
                      </Bloco>
                    )}
                  />
                </ContainerDoisInputs>

                <EspecificacoesSenha>
                  <h1>Sua senha precisa ter:</h1>

                  <div>
                    {watchDadosLogin("senha")?.length >= 8 ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>8 caracteres</p>
                  </div>
                  <div>
                    {RegExp(/(?=.*[A-Z])/).test(watchDadosLogin("senha")) ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>1 letra maiúscula</p>
                  </div>
                  <div>
                    {RegExp(/[a-z]/).test(watchDadosLogin("senha")) &&
                      typeof watchDadosLogin("senha") !== "undefined" ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>1 letra minúscula</p>
                  </div>
                  <div>
                    {RegExp(/(?=.*\d)/).test(watchDadosLogin("senha")) ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>1 número</p>
                  </div>
                </EspecificacoesSenha>
                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => {
                      resetDadosLogin({
                        senha: "",
                        confirmarSenha: "",
                        usuario: "",
                        email: dados.email,
                      });
                      setPaginaAtual(2);
                    }}
                  />
                  <BotaoVerdePreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Finalizar cadastro"
                    disabled={isSubmitting && !botaoHabilitado}
                    onClick={handleSubmitDadosLogin(onHandleSubmitDadosLogin)}
                  />
                </ContainerBotoes>
              </>
            )}
          </Conteudo>
        </ContainerConteudo>
      </ContainerGlobal>
      <GerarModalResponsivo
        ativo={abrirModalRecuperarDados}
        children={(close) => <ModalConfirmar close={close} />}
      />
      <GerarModalResponsivo
        ativo={abrirModalCadastroFinalizado}
        closeOnEscape={false}
        permitirFechar={false}
        children={(close) => <ModalCadastroFinalizado />}
      />
    </>
  );
};

export default Cadastro;
