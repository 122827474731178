import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import darkMode from '../../assets/icons/dark_mode.svg';
import lightMode from '../../assets/icons/wb_sunny.svg';
export const SwitchEstilizado = styled(Switch)`
  margin: 0;
 >  span {
    margin: 0;
  }

  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.cores.neutral100};
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      background-color: transparent;
      height: min-content;
      /* display: flex;
            align-items: center;
            justify-content: center; */
      margin-top: 3px;
      content: ${({ theme }) =>
    `url(${theme.temaDarkAtivo ? darkMode : lightMode})`};
    }
  }

  .MuiSwitch-track {
    background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? theme.adaptativo("", "actionDisable")
      : "rgba(144, 205, 255, 1)"} !important;
  }
/* 
  &.MuiSwitch-root {
    width: 48px;
    height: 24px;
  } */
`;

export const OpcaoItemTema = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: rgba(35, 160, 232, 0.15);
  }

  ${(props) =>
    props.efeito &&
    `
  
      &:hover{
          animation: animacaoBotaoAdicionar 0.9s linear reverse infinite;
      }

      @keyframes animacaoBotaoAdicionar {
          0% {
              transform: scale(1);
          }
          50%{
              transform: scale(0.95);
          }
          100% {
              transform: scale(1);
          }
      }
    `};
`;


export const TextoItemTema = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.cores.textoSimples};
  white-space: nowrap;
`;