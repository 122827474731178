import {
  faBuilding,
  faCashRegister,
  faClose,
  faGear,
  faSliders,
  faTableCellsLarge,
  faUserGroup,
  faWineGlass,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import * as S from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";
import { IconeBotao } from "../../pages/selecaoEmpresas/styles";
import FuncionamentoEmpresa from "../../components/BotaoFuncionamentoEmpresa";
import { usePermissoesLocalStorage } from "../../utils/usePermissoesLocalStorage";

export function BotaoEngrenagem() {
  const [modalEngrenagem, setModalEngrenagem] = useState(false);
  const theme = useTheme();
  const location = useLocation()
  const tipoEmpresa = JSON.parse(localStorage.getItem("sessao"))?.empresaDTO
    ?.tipoEmpresa;
  const { verificarPermissao } = usePermissoesLocalStorage()
  let sessao = JSON.parse(localStorage.getItem("sessao"));
  const permissaoPagamento = verificarPermissao("PESSOA_MINHA_EMPRESA")
  const permissaoPerfil = verificarPermissao("PESSOA_CAD_USUARIO_PERFIL")
  const permissaoEventoPUB = verificarPermissao("PESSOA_CAD_PUB_EVENTO")
  const permissaoPerfilPUB = verificarPermissao("PESSOA_CAD_PUB_PERFIL")
  const contratoWaymenu = sessao?.contratoWaymenuAtualDTO?.listaItens;
  const contratoWaychef = sessao?.contratoWaychefAtualDTO?.listaItens;
  const contratosUsuarios = ["BILHETAGEM", "CARDAPIO_DIGITAL", "PEDIDOS"]




  function renderModal() {
    return (
      <S.ContainerModal onClick={() => setModalEngrenagem(false)}>
        <S.PainelModal onClick={(e) => e.stopPropagation()} >
          <S.LinhaTitulo>
            <h5>Configurações</h5>

            <IconeBotao onClick={() => setModalEngrenagem(false)} >

              <S.IconeMenu
                color={theme.cores.textoSimples}
                icon={faClose}
              />
            </IconeBotao>
          </S.LinhaTitulo>
          {tipoEmpresa !== "PLATAFORMA" && tipoEmpresa !== "INTEGRADOR" && (
            <FuncionamentoEmpresa />
          )}
          <S.AccordionStyled>
            <S.AccordionItem
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.cores.neutral10 }} />
              }
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <S.IconeMenu
                icon={faUserGroup}
                color={theme.cores.textoSimples}
                style={{ marginRight: 12 }}
              />
              <S.TypographyTitulo>Configurações de usuários</S.TypographyTitulo>
            </S.AccordionItem>
            <S.AccordionConteudo>
              {
                permissaoPerfil.consultar && (
                  <>
                    <S.LinkStyled to="/cadastros/minha-empresa/perfis-usuarios">
                      <p>Perfis de usuários</p>
                    </S.LinkStyled>
                    <S.Separador />
                  </>
                )
              }
              {verificarPermissao("PESSOA_CAD_USUARIO").consultar && (
                <>
                  <S.LinkStyled to="/cadastros/minha-empresa/usuarios">
                    <p>Usuários</p>
                  </S.LinkStyled>
                  <S.Separador />
                </>
              )}
            </S.AccordionConteudo>
          </S.AccordionStyled>
          <S.AccordionStyled>
            <S.AccordionItem
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.cores.neutral10 }} />
              }
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <S.IconeMenu
                icon={faBuilding}
                color={theme.cores.textoSimples}
                style={{ marginRight: 12 }}
              />
              <S.TypographyTitulo>Dados da empresa</S.TypographyTitulo>
            </S.AccordionItem>

            <S.AccordionConteudo>
              {/* <S.LinkLinkStyled>
                    <p>Funcionários</p>
                    </S.LinkLinkStyled> */}
              {/* <S.Separador /> */}
              {tipoEmpresa !== "PLATAFORMA" && tipoEmpresa !== "INTEGRADOR" && (
                <>
                  <S.LinkStyled to="/dados-empresa">
                    <p>Minha Empresa</p>
                  </S.LinkStyled>
                  <S.Separador />
                </>
              )}

              {permissaoPagamento.consultar && (
                <>
                  <S.LinkStyled to="/gerenciar-plano">
                    <p>Pagamento</p>
                  </S.LinkStyled>
                  <S.Separador />
                </>
              )}
            </S.AccordionConteudo>

          </S.AccordionStyled>
          {tipoEmpresa !== "PLATAFORMA" && tipoEmpresa !== "INTEGRADOR" && (
            <>
              <S.AccordionStyled>
                <S.AccordionItem
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: theme.cores.neutral10 }} />
                  }
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <S.IconeMenu
                    color={theme.cores.textoSimples}
                    icon={faSliders}
                    style={{ marginRight: 12 }}
                  />
                  <S.TypographyTitulo> Parâmetros </S.TypographyTitulo>
                </S.AccordionItem>
                <S.AccordionConteudo>
                  {verificarPermissao("PARAM_CAD_CANAIS_VENDA").consultar && (
                    <>

                      <S.LinkStyled to="/parametros/canais-de-venda">
                        <p>Canais de venda</p>
                      </S.LinkStyled>
                      <S.Separador />
                    </>)
                  }

                  {/* <S.Separador />
                                    <S.LinkStyled to="">
                                        <p>Contingência Fiscal</p>
                                    </S.LinkStyled>
                                    <S.Separador />
                                    <S.LinkStyled to="/parametros/download-de-aplicativos">
                                        <p>Download de aplicativos </p>
                                    </S.LinkStyled>
                                    <S.Separador />
                                    <S.LinkStyled to="">
                                        <p>Emissores Fiscais </p>
                                    </S.LinkStyled>
                                    <S.Separador />
                                    <S.LinkStyled to="/parametros/meus-dipositivos">
                                        <p>Meus dispositivios </p>
                                    </S.LinkStyled>
                                    <S.Separador />
                                    <S.LinkStyled to="">
                                        <p>Minhas balanças </p>
                                    </S.LinkStyled>
                                    <S.Separador />
                                    <S.LinkStyled to="/parametros/minhas-impressoras/listagem">
                                        <p>Minhas impressoras </p>
                                    </S.LinkStyled>
                                    <S.Separador /> */}
                  <S.SubTitulo>
                    <S.IconeMenu
                      style={{ marginLeft: 12 }}
                      icon={faCashRegister}
                      color="rgba(28, 27, 31, 1)"
                    />
                    <p>Caixa</p>
                  </S.SubTitulo>

                  {verificarPermissao("PARAM_CAD_FIN_COND_PAG").consultar && (
                    <>
                      <S.LinkStyled to="/cadastros/minha-empresa/formas-de-pagamento/listagem">
                        <p>Formas de pagamento </p>
                      </S.LinkStyled>
                      <S.Separador />
                    </>
                  )}
                  {/* <S.LinkStyled to="">
                                        <p>Conferência </p>
                                    </S.LinkStyled>
                                    <S.Separador /> */}
                </S.AccordionConteudo>
              </S.AccordionStyled>
              {/* <S.AccordionStyled>
                                <S.AccordionItem
                                    expandIcon={
                                        <ExpandMoreIcon sx={{ color: theme.cores.neutral10 }} />
                                    }
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <S.IconeMenu
                                        icon={faTableCellsLarge}
                                        color={theme.cores.textoSimples}
                                        style={{ marginRight: 12 }}
                                    />
                                    <S.TypographyTitulo> Módulos </S.TypographyTitulo>
                                </S.AccordionItem>
                                <S.AccordionConteudo>
                                    <S.LinkStyled to="/parametros/modulos/listagem">
                                        <p>Habilitar Módulos</p>
                                    </S.LinkStyled>
                                    <S.Separador />
                                    {contratoWaychef && contratoWaychef.includes("MODULO_VENDA_PUB") && (
                                        <>


                                            <S.SubTitulo>
                                                <S.IconeMenu
                                                    style={{ marginLeft: 20 }}
                                                    icon={faWineGlass}
                                                    color={theme.cores.textoSimples}
                                                />
                                                <p>Módulo PUB</p>
                                            </S.SubTitulo>
                                            {permissaoEventoPUB.consultar && (
                                                <>
                                                    <S.LinkStyled to="/modulos/modulo-pub/criar-evento/listagem">
                                                        <p>Criar Evento </p>
                                                    </S.LinkStyled>
                                                    <S.Separador />
                                                </>
                                            )}
                                            {permissaoPerfilPUB.consultar && (
                                                <>
                                                    <S.LinkStyled to="/modulos/modulo-pub/perfil-cartao/listagem">
                                                        <p>Perfil de Cartão </p>
                                                    </S.LinkStyled>
                                                    <S.Separador />
                                                </>)}
                                            {permissaoPerfil.consultar && (
                                                <>
                                                    <S.LinkStyled to="/modulos/modulo-pub/bloqueio-cartoes">
                                                        <p>Bloqueio de Cartões </p>
                                                    </S.LinkStyled>
                                                    <S.Separador />
                                                </>
                                            )}
                                        </>)}
                                </S.AccordionConteudo>
                            </S.AccordionStyled> */}
            </>
          )}

        </S.PainelModal>
      </S.ContainerModal>
    );
  }


  useEffect(() => {
    setModalEngrenagem(false)
  }, [location])
  return (
    <>
      <S.IconeMenu style={{ cursor: "pointer" }} icon={faGear} onClick={() => setModalEngrenagem(true)} />
      {modalEngrenagem && renderModal()}
    </>
  );
}

export default BotaoEngrenagem;
