export const formatarCPFTempoReal = (value) => {
    try {
        console.log(value)
        value.target.value = value.target.value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
        return value;
    } catch (error) {
        console.error(error)
        return value;
    }
}