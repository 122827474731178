import styled from "@emotion/styled";
import Switch from "../Switch";

export const SwitchEstilizado = styled(Switch)`
  margin: 0;
  span {
    margin: 0;
  }

  .MuiSwitch-thumb {
    background-color: ${({ theme, checked }) =>
    checked
      ? theme.cores.surfaceActionHoverSuccess
      : theme.cores.neutral100} !important;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      height: min-content;
      fill: ${({ theme, checked }) =>
    checked
      ? theme.cores.surfaceActionHoverSuccess
      : theme.cores.neutral100} !important;
      margin-top: 1px;
      transform: scale(0.17);
    }
  }

  .MuiSwitch-track {
    background-color: ${({ theme, checked }) =>
    checked
      ? theme.cores.semanticSuccess2
      : theme.cores.semanticDanger4} !important;
  }
`;

export const BaseBotaoFatura = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 8px;
  padding: 1px 0px;
  background-color: transparent;
  gap: 8px;

  p {
    color: ${({ theme }) => theme.cores.textoSimples};
    width: fit-content;
    font-size: 15px;
    padding: 0px 8px 0px 0px;
  }

  @media (max-width: 1008px) {
    width: 128px;
  }

  @media (max-width: 864px) {
    width: 100%;

    p {
      font-size: 12px;
    }
  }
`;

export const MenuItemFilhoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px;
  border-bottom: 0.2px solid #ccc3;

  p {
    text-overflow: ellipsis;
    width: 100%;
    padding: 8px 0;
  }

  &:has(p):hover {
    background-color: ${(props) => props.theme.cores.brand10neutral20};
    border-radius: 5px;
  }
`;

export const Loader = styled.span`
  padding: 1px 4px;
margin-top: 5px;
    width: 24px;
    height: 24px;
    border: 4px solid ${({ theme }) => theme.cores.textoSimples};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

`