import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { toast } from 'react-toastify';
import 'react-spring-bottom-sheet/dist/style.css';
import 'reactjs-popup/dist/index.css';
import { useTheme } from '@emotion/react';
import { ReactComponent as IconeExpandir } from '../../assets/icons/expand_more.svg';
import { ReactComponent as IconeMenuMobile } from '../../assets/icons/more_vert.svg';
import { ReactComponent as IconeLoja } from '../../assets/icons/storefront.svg';
import WaybeImagemGrande from '../../assets/images/waybe_logo.png';
import ImagemModal from '../../assets/images/imagem_empresa_modal.jpg';
import { dados } from './dados_mockados.js';
import objetoNavegacao from '../../routes/objetoPadraoNavegacao';
import {
  AlterarLojaContainer,
  BotaoTransparente,
  CabecalhoModal,
  Coluna,
  Container,
  ContainerDireita,
  ContainerInput,
  ContainerMenuItem,
  ContainerTabela,
  Input,
  Linha,
  LinhaIcones,
  LinhaMenusContainer,
  MenuItemFilhoContainer,
  PopupCustomizado,
  StyledBottomSheet,
  TextoAlterarLoja,
  TextoCabecalhoModal,
  TextoMenuItem,
  TextoVisualizar,
  ImgEmpresaModal,
  TextoNavegacao,
  LinhaMenusContainerFixo,
  NomeEmpresa,
  TipoEmpresa,
  BlocoItem,
} from './styles';

import useTamanhoTela from "../../utils/useTamanhoTela";
import { MagnifyingGlass, X } from "phosphor-react";
import { IconeBotao } from "../../pages/selecaoEmpresas/styles";
import TabelaOrdenada from "../TabelaOrdenada";
import { useNavigate, useLocation } from "react-router-dom";
import ComponentePaginado from "../ComponentePaginado";
import Configuracoes from "../BotaoConfiguracao";
import Notificacoes from "../BotaoNotificacoes";
import Perfil from "../BotaoPerfil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TemaCustomizadoContext } from "../../contexts/theme";
import { useApi } from "../../contexts/api";
import { useMenu } from "../../contexts/menu";
import removerCaracteresEspeciais from "../../utils/removerCaracteresEspeciais";
import { useLoader } from "../../contexts/loader";
import SelecionarEmpresaPopup from "../ModalSelecionarEmpresasPopup";
import { useAuth } from "../../contexts/auth";
import tornarSlider from "../../utils/tornarSlider";
import verificarPermissaoContrato from "../../utils/verificarPermissaoContrato";
import { result } from "lodash";
import FuncionamentoEmpresa from "../BotaoFuncionamentoEmpresa";
import BotaoEngrenagem from "../BotaoEngrenagem";
import ExpandableSearch from "./components/ExpandableSearch";
export let menusLiberados = [];

const Header = ({ paginaCashback, edicao = false, nomeCashback = '' }) => {
  const funcoesBotaoNavegacao = {
    'function': (filho) => {
      filho();
    },
    'string': (filho) => { },
  };

  //////////////////////////////////////////// Variáveis, estados e Funções ////////////////////////////////////////////

  const refTabelaScroll = useRef();

  const { getTemaDarkAtivo } = useContext(TemaCustomizadoContext);
  const booleanTema = getTemaDarkAtivo();

  const theme = useTheme();

  const { atualizarMenu, abrirCarregamento, fecharCarregamento } = useLoader();
  const { largura } = useTamanhoTela();
  const { trocarEmpresa } = useAuth();
  const [menuFixo, setMenuFixo] = useState(false);
  const [
    mostrarModalSelecionarEmpresaPopup,
    setMostrarModalSelecionarEmpresaPopup,
  ] = useState(false);
  const [listaMenuAtual, setListaMenuAtual] = useState();

  const LARGURA_QUEBRA = 864;
  let versaoDesktopAtiva = largura > LARGURA_QUEBRA;
  const [abrir, setAbrir] = useState(false);

  const { get } = useApi();

  const {
    listaMenu,
    atualizarHeaderFixo,
    buscarDashboardsStorage,
    setCarregandoDashboard,
    carregandoDashboard,
    buscarDadosEmpresaStorage,
    dadosEmpresa,
    buscarDashboards,
    listaDashboards,
  } = useMenu();

  const navigate = useNavigate();

  const location = useLocation();

  function fecharModal() {
    setAbrir(false);
  }

  const useScrollDirection = () => {
    const THRESHOLD = 0;
    const [scrollDirection, setScrollDirection] = React.useState('up');

    const blocking = React.useRef(false);
    const prevScrollY = React.useRef(0);

    React.useEffect(() => {
      prevScrollY.current = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;

        if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
          const newScrollDirection =
            scrollY > prevScrollY.current ? 'down' : 'up';

          setScrollDirection(newScrollDirection);

          prevScrollY.current = scrollY > 0 ? scrollY : 0;
        }

        blocking.current = false;
      };

      const onScroll = () => {
        if (!blocking.current) {
          blocking.current = true;
          window.requestAnimationFrame(updateScrollDirection);
        }
      };

      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }, [scrollDirection]);

    return scrollDirection;
  };

  const dadosTabela = () => {
    return {
      paginas: 20,
      pagina: 1,
      colunas: [
        { expandivel: false, valor: ' ' },
        { expandivel: false, valor: '' },
        { expandivel: false, valor: 'Nome Fantasia' },
        { expandivel: false, valor: 'Tipo' },
        { expandivel: false, valor: 'Grupo' },
        { expandivel: false, valor: 'Integrador' },
        { expandivel: false, valor: 'Insc.Federal' },
        { expandivel: false, valor: 'Cidade' },
        { expandivel: false, valor: 'UF' },
        { expandivel: false, valor: 'Plano' },
        { expandivel: false, valor: 'Assinatura' },
        { expandivel: false, valor: 'Data Cadastro' },
        { valor: '  ' },
      ],
      dados: [
        {
          ' ': <TextoVisualizar>Visualizar</TextoVisualizar>,
          '': <ImgEmpresaModal src={ImagemModal} />,
          'Nome Fantasia': 'Waychef',
          Descrição: 'Desconto 10%',
          Tipo: 'Franqueado',
          Grupo: 'Matriz',
          Integrador: 'PRODATI',
          'Insc.Federal': '410.095.320-81',
          Cidade: 'São Paulo',
          UF: 'SP',
          Plano: 'Bronze',
          Assinatura: 'Mensal',
          'Data Cadastro': '07/11/2022',
        },
        {
          ' ': <TextoVisualizar>Visualizar</TextoVisualizar>,
          '': <ImgEmpresaModal src={ImagemModal} />,
          'Nome Fantasia': 'Waychef',
          Descrição: 'Desconto 10%',
          Tipo: 'Franqueado',
          Grupo: 'Matriz',
          Integrador: 'PRODATI',
          'Insc.Federal': '410.095.320-81',
          Cidade: 'São Paulo',
          UF: 'SP',
          Plano: 'Bronze',
          Assinatura: 'Mensal',
          'Data Cadastro': '07/11/2022',
        },
        {
          ' ': <TextoVisualizar>Visualizar</TextoVisualizar>,
          '': <ImgEmpresaModal src={ImagemModal} />,
          'Nome Fantasia': 'Waychef',
          Descrição: 'Desconto 10%',
          Tipo: 'Franqueado',
          Grupo: 'Matriz',
          Integrador: 'PRODATI',
          'Insc.Federal': '410.095.320-81',
          Cidade: 'São Paulo',
          UF: 'SP',
          Plano: 'Bronze',
          Assinatura: 'Mensal',
          'Data Cadastro': '07/11/2022',
        },
        {
          ' ': <TextoVisualizar>Visualizar</TextoVisualizar>,
          '': <ImgEmpresaModal src={ImagemModal} />,
          'Nome Fantasia': 'Waychef',
          Descrição: 'Desconto 10%',
          Tipo: 'Franqueado',
          Grupo: 'Matriz',
          Integrador: 'PRODATI',
          'Insc.Federal': '410.095.320-81',
          Cidade: 'São Paulo',
          UF: 'SP',
          Plano: 'Bronze',
          Assinatura: 'Mensal',
          'Data Cadastro': '07/11/2022',
        },
        {
          ' ': <TextoVisualizar>Visualizar</TextoVisualizar>,
          '': <ImgEmpresaModal src={ImagemModal} />,
          'Nome Fantasia': 'Waychef',
          Descrição: ' PRODATIDesconto 10%',
          Tipo: 'Franqueado',
          Grupo: 'Matriz',
          Integrador: 'PRODATI',
          'Insc.Federal': '410.095.320-81',
          Cidade: 'São Paulo',
          UF: 'SP',
          Plano: 'Bronze',
          Assinatura: 'Mensal',
          'Data Cadastro': '07/11/2022',
        },
      ],
    };
  };

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position > 130) {
      setMenuFixo(true);
      //atualizarHeaderFixo(true)
    } else {
      setMenuFixo(false);
      //atualizarHeaderFixo(false)
    }

    if (position > 160) {
      atualizarHeaderFixo(true);
    } else {
      atualizarHeaderFixo(false);
    }
  };

  const identificarRotaSelecionada = (rotaAtual) => {
    try {
      if (location.pathname == '/' && rotaAtual == 'dashboard') {
        return true;
      }

      if (rotaAtual === 'produtos') return false;

      const rota = location.pathname.split('/');

      if (rota[1] == rotaAtual) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    tornarSlider(refTabelaScroll.current);
  }, [refTabelaScroll]);

  useEffect(() => {
    const sessao = JSON.parse(localStorage.getItem('sessao'));

    buscarDashboardsStorage();

    buscarDadosEmpresaStorage();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (listaDashboards != undefined) {
      try {
        const menus = { ...listaMenuAtual };

        //Tratativa temporaria para não listar dashboards para INTEGRADOR e PLATAFORMA

        const sessao = JSON.parse(localStorage.getItem("sessao"));

        if (
          ["PLATAFORMA", "INTEGRADOR"].includes(sessao?.empresaDTO?.tipoEmpresa)
        ) {
          const dashboardsPlataforma = [];

          dashboardsPlataforma["Novo"] = () => {
            navigate("/dashboard/novo");
          };
          menus.menus.Dashboard.filhos = dashboardsPlataforma;
        } else {
          menus.menus.Dashboard.filhos = listaDashboards;
        }

        setListaMenuAtual(menus);
      } catch (error) { }
    }
  }, [listaDashboards]);

  // const renderizarItensMenu = useCallback(() => {
  //   return verificarPermissaoContrato(listaMenuAtual.menus);
  // }, [listaMenuAtual])

  useEffect(() => {
    setListaMenuAtual(listaMenu);
  }, [listaMenu]);

  //////////////////////////////////////////// Layout da Página ////////////////////////////////////////////
  function Corpo(params) {
    return versaoDesktopAtiva ? (
      <Container>
        <LinhaIcones>
          <WaybeLogo />
          <AlterarLoja />
          <ExpandableSearch />
          <Notificacoes />
          <BlocoItem >
            <Perfil />
            <BotaoEngrenagem />
          </BlocoItem>
        </LinhaIcones>
        <LinhaMenus menusDisponiveis={listaMenu.menus} />
      </Container>
    ) : (
      <Container>
        <LinhaIcones>
          <Coluna
            style={{
              alignItems: 'flex-start',
              minWidth: 250,
            }}
          >
            <WaybeLogo />
            <AlterarLoja />
          </Coluna>
          <Notificacoes mobile={true} />
          <Configuracoes mobile={true} />
        </LinhaIcones>
      </Container>
    );
  }

  //////////////////////////////////////////// COMPONENTES  ////////////////////////////////////////////

  const WaybeLogo = () => {
    function larguraResponsiva() {
      if (largura > LARGURA_QUEBRA) {
        return 160;
      } else {
        return 60;
      }
    }
    return (
      <img
        onClick={() => navigate('/')}
        src={WaybeImagemGrande}
        alt="Logo da empresa Waybe"
        style={{
          width: larguraResponsiva(),
          marginBottom: 10,
          cursor: 'pointer',
        }}
      />
    );
  };

  const identificarTipoUsuario = () => {
    try {
      //Verificar se o usuario é do tipo master
      const sessao = JSON.parse(localStorage.getItem('sessao'));
      if (
        sessao?.empresaDTO.tipoEmpresa == 'PLATAFORMA' ||
        sessao?.empresaDTO.tipoEmpresa == 'INTEGRADOR'
      ) {
        setMostrarModalSelecionarEmpresaPopup(true);
      } else {
        navigate('/empresa');
      }
    } catch (error) {
      console.error(error);
      navigate('/empresa');
    }
  };

  const AlterarLoja = () => {
    return versaoDesktopAtiva ? (
      <AlterarLojaContainer onClick={() => identificarTipoUsuario()}>
        <Linha
          style={{ alignItems: versaoDesktopAtiva ? 'center' : 'flex-start' }}
        >
          <IconeLoja
            style={{
              width: 30,
              marginRight: 10,
              fill: theme.cores.brand40neutral100,
            }}
          />
          <Coluna style={{ alignItems: 'flex-start' }}>
            <NomeEmpresa>{dadosEmpresa?.apelido}</NomeEmpresa>
            <TipoEmpresa>{dadosEmpresa?.tipoEmpresa}</TipoEmpresa>
          </Coluna>
        </Linha>
        <BotaoTransparente>
          <TextoAlterarLoja style={{ fontSize: 14 }}>
            Alterar empresa
          </TextoAlterarLoja>
        </BotaoTransparente>
        <StyledBottomSheet open={abrir} onDismiss={() => setAbrir(false)}>
          <CabecalhoModal>
            <TextoCabecalhoModal>Alternar empresa</TextoCabecalhoModal>
            <IconeBotao onClick={fecharModal}>
              <X size={24} color={theme.cores.botaoModal} />
            </IconeBotao>
          </CabecalhoModal>

          <ContainerDireita>
            <ContainerInput>
              <Input placeholder="Pesquisar empresa" />
              <IconeBotao>
                <MagnifyingGlass
                  size={22}
                  color={theme.cores.corInput}
                  style={{ marginLeft: -40 }}
                />
              </IconeBotao>
            </ContainerInput>
          </ContainerDireita>
          <ContainerTabela>
            <TabelaOrdenada dados={dadosTabela} alternarEmpresa={true} />
          </ContainerTabela>
        </StyledBottomSheet>
      </AlterarLojaContainer>
    ) : (
      <AlterarLojaContainer onClick={() => identificarTipoUsuario()}>
        <Linha style={{ width: '100%' }}>
          <IconeLoja
            style={{
              width: 20,
              marginTop: -5,
              marginRight: 10,
              fill: theme.cores.brand40neutral100,
            }}
          />
          <Coluna
            style={{
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <NomeEmpresa>{dadosEmpresa?.apelido}</NomeEmpresa>
            <Linha style={{ justifyContent: 'space-between', width: '100%' }}>
              <TipoEmpresa>{dadosEmpresa?.tipoEmpresa}</TipoEmpresa>
              <BotaoTransparente>
                <TextoAlterarLoja style={{ fontSize: 14 }}>
                  Alterar empresa
                </TextoAlterarLoja>
              </BotaoTransparente>
              <StyledBottomSheet open={abrir} onDismiss={() => setAbrir(false)}>
                <CabecalhoModal>
                  <TextoCabecalhoModal>Alternar empresa</TextoCabecalhoModal>
                  <IconeBotao onClick={fecharModal}>
                    <X size={24} color={theme.cores.botaoModal} />
                  </IconeBotao>
                </CabecalhoModal>

                <ContainerDireita>
                  <ContainerInput>
                    <Input placeholder="Pesquisar empresa" />
                    <IconeBotao>
                      <MagnifyingGlass
                        size={22}
                        color={theme.cores.corInput}
                        style={{ marginLeft: -40 }}
                      />
                    </IconeBotao>
                  </ContainerInput>
                </ContainerDireita>
                <ContainerTabela>
                  <TabelaOrdenada dados={dadosTabela} alternarEmpresa={true} />
                </ContainerTabela>
              </StyledBottomSheet>
            </Linha>
          </Coluna>
        </Linha>
      </AlterarLojaContainer>
    );
  };

  const LinhaMenus = ({ menusDisponiveis }) => {
    let refMenuScroll = useRef();
    menusLiberados = renderMenu(menusDisponiveis);

    useEffect(() => {
      tornarSlider(refMenuScroll.current);
    }, [refMenuScroll]);

    // const renderMenu = (menu) => {
    //   if (menu['ocultar']) {
    //     return false;
    //   }
    //   console.log(menu['ocultar'])

    //   if (
    //     menu['tipoEmpresa'] != null &&
    //     !menu['tipoEmpresa'].includes(sessao.empresaDTO.tipoEmpresa)
    //   ) {
    //     return false;
    //   }

    //   if (
    //     menu['tipoEmpresa'] == null &&
    //     !['FRANQUIA', 'FRANQUEADO', 'MATRIZ', 'FILIAL'].includes(
    //       sessao.empresaDTO.tipoEmpresa
    //     )
    //   ) {
    //     return false;
    //   } else

    //     if (permissoesWaybe && menu['itemContratoWaybe'] != null) {
    //       let temNoContrato = permissoesWaybe.includes(menu['itemContratoWaybe']);
    //       console.log(temNoContrato)
    //       if (!temNoContrato) {
    //         return false;
    //       }
    //     }
    //   console.log(menu.permissoes)
    //   console.log(menusDisponiveis)
    //   console.log(listaPrivilegios)
    //   console.log(menu['permissoes'])
    //   if (isMaster) {
    //     return true;
    //   }
    //   if (listaPrivilegios && menu['permissoes'] != null) {
    //     return menusDisponiveis['permissoes'].every((perm) =>
    //       Object.keys(listaPrivilegios).includes(perm)
    //     );
    //   }

    //   return true;
    // };

    return (
      <LinhaMenusContainer
        ref={refMenuScroll}
        menuFixo={menuFixo}
        rotaCardapio={location.pathname.includes(
          '/produtos/cardapio/wayservice/avancado'
        )}
      >
        {menusLiberados.map((menu, index) => {
          return (
            <MenuItemNovo
              ref={refTabelaScroll}
              menu={menu}
              titulo={menu.nome}
              key={menu.nome + index}
            />
          );
        })}
      </LinhaMenusContainer>
    );
  };
  const renderMenu = (todosMenus) => {
    let sessao = JSON.parse(localStorage.getItem("sessao"));
    const permissoesWaybe = sessao?.contratoWaybeAtualDTO?.permissoesWaybe;
    const permissoesWaymenu = sessao?.contratoWaymenuAtualDTO?.listaItens;
    const permissoesWaychef = sessao?.contratoWaychefAtualDTO?.listaItens;
    let perfil = sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO;
    let listaPrivilegios = perfil?.listaPrivilegios;
    let isMaster = perfil?.tipoPerfilUsuario === "MASTER";

    let resultado = [];

    for (const item of todosMenus) {
      if (!item) {
        continue;
      }

      if (item["ocultar"]) {
        continue;
      }

      if (
        item["tipoEmpresa"] != null
      ) {
        let temTipoEmpresa = item["tipoEmpresa"].some((tipo) => tipo === sessao?.empresaDTO.tipoEmpresa)

        if (!temTipoEmpresa) {
          continue;
        }
      }

      if (permissoesWaybe && item["itemContratoWaybe"] != null) {
        let temNoContrato = item["itemContratoWaybe"].every((contrato) =>
          permissoesWaybe.includes(contrato)
        );
        if (!temNoContrato) {
          continue;
        }
      }

      if (permissoesWaychef && item["itemContratoWaychef"] != null) {
        let temNoContrato = item["itemContratoWaychef"].every((contrato) =>
          permissoesWaychef.includes(contrato)
        );
        if (!temNoContrato) {
          continue;
        }
      }

      if (permissoesWaymenu && item["itemContratoWaymenu"] != null) {
        let temNoContrato = item["itemContratoWaymenu"].every((contrato) =>
          permissoesWaymenu.includes(contrato)
        );

        if (!temNoContrato) {
          continue;
        }
      }

      if (!isMaster) {
        if (listaPrivilegios && item["permissoes"] != null) {
          const permissoesValidas = item["permissoes"].every((perm) =>
            Object.keys(listaPrivilegios).includes(perm)
          );

          if (!permissoesValidas) {
            continue;
          }
        }
      }

      if (item.filhos) {
        const filhosResultado = renderMenu(item.filhos);
        if (filhosResultado.length > 0) {
          resultado.push({ ...item, filhos: filhosResultado });
        } else {
          continue;
        }
      } else {
        resultado.push(item);
      }
    }
    return resultado;
  };

  const MenuItemNovo = ({ menu, titulo, larguraMinima }) => {
    const [aberto, setAberto] = useState(false);

    return menu.filhos != null && menu.filhos.length > 0 ? (
      <PopupCustomizado
        onOpen={() => setAberto(true)}
        onClose={() => setAberto(false)}
        trigger={
          <ContainerMenuItem
            style={{ minWidth: larguraMinima, ...menu?.styleContainer }}
            $aberto={aberto}
            atualizar={
              identificarRotaSelecionada(
                removerCaracteresEspeciais(titulo).toLowerCase()
              ) && atualizarMenu
                ? true
                : false
            }
            selecionado={identificarRotaSelecionada(
              removerCaracteresEspeciais(titulo).toLowerCase()
            )}
          >
            {menu?.svg ? (
              menu?.svg
            ) : (
              <FontAwesomeIcon
                icon={menu.icone}
                style={{ color: theme.cores.textoSimples }}
              />
            )}

            <TextoMenuItem className="icone-menu-painel-transicao">
              {titulo}
            </TextoMenuItem>

            <IconeExpandir className="icone-menu-painel-transicao icone-menu-painel-transicao-inverter" />
          </ContainerMenuItem>
        }
        // position="bottom left"
        position={['bottom left', 'left bottom']}
        keepTooltipInside={true}
        arrowStyle={{ visibility: 'hidden' }}
        offsetY={10} // 4 é a margem, 21 é (60 - 18) / 2, onde 60 é a altura do menu e 18 é a altura conteúdo
      >
        <ComponentePaginado
          Filho={MenuItemFilhoContainer}
          navegacaoInicial={menu.filhos}
        />
      </PopupCustomizado>
    ) : (
      <ContainerMenuItem
        $aberto={aberto}
      // onClick={() => funcoesBotaoNavegacao(menu)}
      >
        <FontAwesomeIcon
          icon={menu.icone}
          style={{ color: theme.cores.textoSimples }}
        />

        <TextoMenuItem className="icone-menu-painel-transicao">
          {titulo}
        </TextoMenuItem>
      </ContainerMenuItem>
    );
  };

  const MenuItem = ({ menu, titulo }) => {
    const [aberto, setAberto] = useState(false);
    return typeof menu === 'object' ? (
      <PopupCustomizado
        onOpen={() => setAberto(true)}
        onClose={() => setAberto(false)}
        trigger={
          <ContainerMenuItem $aberto={aberto}>
            {menu.icone ? menu.icone : <></>}

            <TextoMenuItem className="icone-menu-painel-transicao">
              {titulo}
            </TextoMenuItem>

            <IconeExpandir className="icone-menu-painel-transicao icone-menu-painel-transicao-inverter" />
          </ContainerMenuItem>
        }
        position="bottom left"
        arrowStyle={{ visibility: 'hidden' }}
        offsetY={21 + 4} // 4 é a margem, 21 é (60 - 18) / 2, onde 60 é a altura do menu e 18 é a altura conteúdo
      >
        <ComponentePaginado
          Filho={MenuItemFilhoContainer}
          navegacaoInicial={menu}
        />
      </PopupCustomizado>
    ) : (
      <ContainerMenuItem
        $aberto={aberto}
        onClick={() => funcoesBotaoNavegacao[typeof menu](menu)}
      >
        {menu.icone ? menu.icone : <></>}
        <TextoMenuItem className="icone-menu-painel-transicao">
          {titulo}
        </TextoMenuItem>
      </ContainerMenuItem>
    );
  };

  const MenuMobile = () => {
    return (
      <PopupCustomizado
        trigger={
          <IconeMenuMobile
            style={{
              fill: theme.cores.brand40neutral100,
              marginTop: 20,
              transform: 'scale(1.2)',
            }}
          />
        }
        position="bottom right"
        arrowStyle={{ visibility: 'hidden' }}
      >
        <div style={{ width: 200, height: 200 }}></div>
      </PopupCustomizado>
    );
  };

  ///////////////////////////////////////////////////////////

  const selecionarEmpresa = (id, nome) => {
    try {
      abrirCarregamento(`Carregando ${nome}...`);
      trocarEmpresa({ idEmpresa: id })
        .then(async (dados) => {
          await buscarDashboards(dados.tokenDTO.token);
          await buscarDadosEmpresaStorage();
          toast.success('Empresa alterada com sucesso!');
          setTimeout(() => {
            fecharCarregamento().then(() => {
              window.location.href = '/';
            });
          }, 500);
        })
        .catch((erro) => {
          fecharCarregamento().then(() => {
            toast.error('Não foi possivel alterar empresa!');
          });
        });
    } catch (error) { }
  };

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      {mostrarModalSelecionarEmpresaPopup && (
        <SelecionarEmpresaPopup
          selecionarEmpresa={selecionarEmpresa}
          fecharTabela={() =>
            setMostrarModalSelecionarEmpresaPopup(
              !mostrarModalSelecionarEmpresaPopup
            )
          }
        />
      )}
      {listaMenuAtual != undefined && Corpo()}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundColor: theme.cores.surfaceBackground1,
        }}
      />
    </div>
  );
};

export default Header;